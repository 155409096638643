import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  Flex,
  Heading,
  Box,
  Button,
  Image,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { FormattedMessage, useIntl } from 'react-intl';
import { sibUrl } from 'src/config';

const limitedImage = '/images/LimitedSpots.png';

// eslint-disable-next-line no-useless-escape
const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const WaitlistForm = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const { formatMessage } = useIntl();

  const handleInputChange = e => {
    e.preventDefault();
    isError && setIsError(false);
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (regex.test(email)) {
      // send
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('EMAIL', email);

        await fetch(sibUrl, {
          method: 'POST',
          body: formData,
        });
        setIsLoading(false);
        setIsSuccessful(true);
        console.log('Form data submitted successfully');
      } catch (error) {
        console.error('Error submitting form data', error);
        setIsLoading(false);
        setIsSuccessful(true);
        //setIsError(true);
      }
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };

  return (
    <Flex id={'sib-form'} direction={['column', 'column', 'row']}>
      <Flex
        className={'sib-form-container sib-container'}
        direction={['column']}
        w={['100%', '100%']}
        mr={['10px']}
      >
        <FormControl
          w={'full'}
          className={'sib-input sib-form-block'}
          isInvalid={isError}
        >
          <Box className={'form__entry entry_block'}>
            <Input
              w={['100%']}
              borderRadius={['10px']}
              minH={['50px']}
              _placeholder={{
                color: '#9B9B9B',
              }}
              className={'input entry__field'}
              type="email"
              bg={'white'}
              id={'EMAIL'}
              name={'EMAIL'}
              onChange={handleInputChange}
              value={email}
              placeholder={formatMessage({
                id: 'newsletter.email.placeholder',
                description: 'newsletter email placeholder',
                defaultMessage: 'Enter your email address',
              })}
              required
            />
            <FormErrorMessage
              display={'none'}
              className={'sib-form-message-panel'}
            >
              <Text id="success-message" className={'entry__error'}></Text>
              <Text id="error-message" className={'entry__error'}></Text>
            </FormErrorMessage>
          </Box>
        </FormControl>
      </Flex>
      <Button
        form="sib-form"
        minW={['100%', '130px']}
        minH={['50px']}
        h={['50px']}
        variant={'solid'}
        boxSizing={['border-box']}
        colorScheme={'primary'}
        mt={[4, 4, 0]}
        leftIcon={isSuccessful && <FiCheck />}
        isDisabled={isSuccessful}
        onClick={handleSubmit}
        isLoading={isLoading}
        _hover={{
          bg: 'primary.800',
        }}
        _active={{
          bg: 'primary.400',
        }}
      >
        <Flex>
          <Text color={'white'}>
            {isSuccessful ? (
              <FormattedMessage
                id={'newsletter.button.label.success'}
                description={'newsletter button label success'}
                defaultMessage={'Joined!'}
              />
            ) : (
              <FormattedMessage
                id={'newsletter.button.label'}
                description={'newsletter button label'}
                defaultMessage={'Join'}
              />
            )}
          </Text>
        </Flex>
      </Button>
    </Flex>
  );
};

console.log(WaitlistForm);

export const WaitlistModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      size={['md', 'xl']}
      onClose={onClose}
      scrollBehavior={'inside'}
      isCentered={['false', 'true']}
    >
      <ModalOverlay />
      <ModalContent bg="#f7f9fa" borderRadius={'20px'}>
        <Box
          borderRadius={'20px'}
          w={'full'}
          h={'full'}
          py={[8, 8]}
          px={[4, 50]}
        >
          <ModalHeader>
            <Center>
              <Heading size={['2xl']}>
                <FormattedMessage
                  id={'waitlist.heading.title'}
                  description={'waitlist heading title'}
                  defaultMessage={'Limited Spots Available!'}
                />
              </Heading>
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              position={'relative'}
              justify={'center'}
              w={'100%'}
              py={[4, 8]}
            >
              <Image maxW={'300px'} loading={['lazy']} src={limitedImage} />
            </Flex>
            <Text pb={[8]} textAlign={'center'}>
              <FormattedMessage
                id={'waitlist.description'}
                description={'waitlist.description'}
                defaultMessage={
                  'We only have a small amount of toothbrushes to distribute. Enter your email for more information!'
                }
              />
            </Text>
            <Box>
              <WaitlistForm />
            </Box>
          </ModalBody>
        </Box>
      </ModalContent>
    </Modal>
  );
};
