export const API_URL = process.env.REACT_APP_API_URL;
export const JWT_SECRET = '123456';
export const whitepaperUrl = 'https://whitepaper.nilo.gg/';
export const supportUrl = 'https://support.nilo.gg/';
export const appUrl = 'https://app.nilo.gg/';
export const iosUrl = 'https://apps.apple.com/us/app/nilo-gg/id6448323412';
export const shopUrl =
  'https://www.shopnilo.com/products/nilo-smart-toothbrush';
export const androidUrl =
  'https://play.google.com/store/apps/details?id=com.nilo.gg';
export const blogUrl = 'https://medium.com/nilo-official';
export const sibUrl =
  'https://fc35fbdd.sibforms.com/serve/MUIEAJS19h6MfZR8JZuowuoPPXuB2sh7zv_rv0uq608tC7kC9Ms52PO0q29bk4KCLed14tV7UEAQzuZt8hEkAvdCzlIDpv4pAShBv8TsMxzKjzi4AIbGxs1TWO-mP8SOqg5yzWfxr4RQSyfj49NNGu1MHGD8uaP6vEV2s4ntVmsdCLWa3RX-VrqTxZwgaDjHTkAmE8F2NCSsYTTt';
export const PAGE_URLS = {
  myaccount: 'app/account',
  blog: '/blog',
};
export const TAGS = [
  'oralhealth',
  'events',
  'development',
  'tutorials',
  'partnerships',
];
export const SOCIALS = {
  discord: 'https://discord.gg/nilo',
  twitter: 'https://twitter.com/collectnilo',
};
