import {
  Flex,
  Text,
  Heading,
  SimpleGrid,
  Image,
  Stack,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, Navigate } from 'react-router-dom';
import { Layout } from 'src/components/Layout/Layout';
import { useUser } from 'src/lib/auth';

const loginBg = '/images/login-background-fullheight.svg';
const niloSkating = '/images/login-nilo-skating.png';

export const Login = () => {
  const user = useUser();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const fromSearch = location.state?.from?.search || '?';

  if (user.data) {
    return <Navigate to={from + fromSearch} replace={true} />;
  }

  return (
    <Layout title={'Login'} headTitle={'Login'}>
      <Flex
        h={'100vh'}
        w={'100%'}
        mx={'auto'}
        pb={['0']}
        pt={['150px', '150px', '100px', '90px']}
        px={['2rem', '2rem', '2rem', '3rem']}
        justify={['center']}
        align={['center']}
        position={['relative']}
        bgImage={loginBg}
        bgSize={['cover']}
      >
        <SimpleGrid
          columns={[1, 2]}
          columnGap={[4]}
          maxW={['100%', '100%', '48em', '1200px']}
        >
          <Flex display={['none', 'flex']} px={['0', '5rem']}>
            <Image loading={['lazy']} src={niloSkating} />
          </Flex>
          <Flex w={['100%', '500px']}>
            <Flex
              bg={'white'}
              borderRadius={'30px'}
              px={['5rem']}
              py={['50px']}
              direction={['column']}
              w={'100%'}
            >
              <Heading size={'3xl'}>{'Log In'}</Heading>
              <Stack orientation={'vertical'} spacing={[4]} mt={[4]}>
                <FormControl>
                  <Flex direction={['column']}>
                    <FormLabel color={'#666666'}>{'Email Address'}</FormLabel>
                    <Input
                      value={''}
                      onChange={() => null}
                      placeholder={'Enter Email Address'}
                    />
                  </Flex>
                  <FormErrorMessage></FormErrorMessage>
                </FormControl>
                <FormControl>
                  <Flex direction={['column']}>
                    <FormLabel color={'#666666'}>{'Code'}</FormLabel>
                    <Input
                      value={''}
                      onChange={() => null}
                      placeholder={'11-11-11'}
                    />
                  </Flex>
                  <FormErrorMessage></FormErrorMessage>
                </FormControl>
                <Checkbox pt={[4]} colorScheme={'primary'} spacing={[4]}>
                  <Text color={'#9B9B9B'} lineHeight={'1.2'}>
                    <FormattedMessage
                      id={'login.privacy.agreement.text'}
                      description={'login.privacy.agreement.text'}
                      defaultMessage={
                        'I have read and agreed to the Nilo Terms of Use and Privacy Policy'
                      }
                    />
                  </Text>
                </Checkbox>
                <Button
                  variant={'solid'}
                  colorScheme={'primary'}
                  minW={['100%']}
                >
                  <FormattedMessage
                    id={'login.form.button.submit'}
                    description={'login page login'}
                    defaultMessage={'Login'}
                  />
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Layout>
  );
};
