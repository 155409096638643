import {
  Flex,
  Box,
  Stack,
  Accordion,
  Skeleton,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Await, useLoaderData } from 'react-router-dom';
import { CustomAccordionItem } from 'src/components/Accordion/AccordionItem';
import { FaqRichText } from 'src/components/MDXRichText/MDXRichText';

const topBg = '/images/FaqTopBg.png';
const bottomBg = '/images/FaqBottomBg.png';

const topBgHeight = '17rem';
const bottomBgHeight = '18rem';

const topBgHeightMobile = '4rem';
const bottomBgHeightMobile = '4rem';

export const FaqSection = () => {
  const { faqData } = useLoaderData();

  return (
    <Flex
      w={'100%'}
      mx={'auto'}
      py={['80px']}
      pb={['20px', '80px']}
      px={['2rem', '2rem', '0']}
      justify={['center', 'space-between']}
      align={['center']}
      position={['relative']}
      bg={
        'linear-gradient(68.69deg, #37B8BB -0.07%, #A5CAAE 44.22%, #F7DEB9 90.2%)'
      }
      direction={'column'}
    >
      <Box
        position={'absolute'}
        top={'-3px'}
        height={[topBgHeightMobile, topBgHeight]}
        w={'full'}
        bg={`url('${topBg}') no-repeat`}
        bgPosition={'left top'}
        bgSize={['contain']}
      />
      <Box
        position={'absolute'}
        bottom={'-3px'}
        w={'full'}
        height={[bottomBgHeightMobile, bottomBgHeight]}
        bg={`url('${bottomBg}') no-repeat`}
        bgPosition={'left bottom'}
        bgSize={['contain']}
      />
      <Flex
        mb={[bottomBgHeightMobile, bottomBgHeightMobile, bottomBgHeight]}
        mt={[topBgHeightMobile, topBgHeightMobile, topBgHeight]}
        direction={'column'}
        maxW={['100%', '100%', '48em', '950px']}
        w={['100%', '100%', '48em', '950px']}
        py={['0.5rem']}
      >
        <Flex
          direction={'column'}
          px={['0', '0']}
          py={['50px', '0']}
          justify={['center']}
        >
          <Heading
            as={'h2'}
            color={'#1A4B46'}
            size={'3xl'}
            pr={[0]}
            textAlign={['center']}
          >
            <FormattedMessage
              id={'faq.heading'}
              description={'faq faq heading'}
              defaultMessage={'Frequently Asked Questions'}
            />
          </Heading>
          <Accordion
            w={['100%']}
            maxW={['100%', '950px']}
            mt={['50px']}
            allowMultiple
          >
            <Stack spacing={[4]}>
              <React.Suspense
                fallback={
                  <>
                    <Skeleton
                      w={'full'}
                      h={['97px', '65px']}
                      borderRadius={'20px'}
                    />
                    <Skeleton
                      w={'full'}
                      h={['97px', '65px']}
                      borderRadius={'20px'}
                    />
                    <Skeleton
                      w={'full'}
                      h={['97px', '65px']}
                      borderRadius={'20px'}
                    />
                  </>
                }
              >
                <Await resolve={faqData}>
                  {faqData => {
                    return faqData.map((faq, index) => (
                      <CustomAccordionItem key={index} title={faq?.title}>
                        <FaqRichText content={faq?.body} />
                      </CustomAccordionItem>
                    ));
                  }}
                </Await>
              </React.Suspense>
            </Stack>
          </Accordion>
        </Flex>
      </Flex>
    </Flex>
  );
};
