import {
  Flex,
  Box,
  Heading,
  Text,
  Image,
  Button,
  //chakra,
  Link,
  Hide,
  //shouldForwardProp,
} from '@chakra-ui/react';
//import { motion, isValidMotionProp } from 'framer-motion';
import { track } from '@vercel/analytics';
import React from 'react';
import { FormattedMessage } from 'react-intl';

//const heroFrontFrame = '/images/HeroFrontFrame.svg';
const heroFrontWaveFrame = '/images/HeroFrontWaveFrame.svg';
const heroBambooFrame = '/images/HeroBambooFrame.png';
//const heroAppHomeScreen from '/images/HeroHomeScreen.png';
//const heroNiloBoard from '/images/HeroNiloBoard.png';
//const heroToothbrush from '/images/HeroToothbrush.png';
//const heroGroupedImage = '/images/HeroMainImage.png';
const heroNiloSkatingMobile = '/images/HeroNiloSkatingMobile.png';

//const heroAppScreen = '/images/HeroAppScreenBright.png';
//const heroNiloSkating = '/images/HeroNiloSkating2.png';
//const heroNiloSkatingShadow = '/images/HeroNiloSkatingShadow2.png';

/* comment out animation images

const initView = {
    heroNiloSkating: { top: 207, left: 40 },
  heroNiloSkatingShadow: { top: 286, left: 134 },
};

const whileInView = {
  heroNiloSkating: {
    translateX: -12,
    translateY: -8,
    transition: { delay: 0.1, duration: 0.3 },
  },
  heroNiloSkatingShadow: {
    translateX: 6,
    translateY: 2,
    transition: { delay: 0.1, duration: 0.2 },
  },
};
const HeroAnimatedImage = () => {
  return (
    <Box display={'none'}>
      <MotionBox
        w={589}
        h={629}
        display={'flex'}
        position={'relative'}
        alignItems={'center'}
        justifyContent={['center']}
      >
        <Flex
          position={'relative'}
          w={`${589}px`}
          h={`${629}px`}
          justify={['center', 'flex-start']}
        >
          <Flex position={'relative'} w={'100%'}>
            <Image
              loading={['lazy']}
              position={'absolute'}
              top={0}
              left={0}
              src={heroAppScreen}
            />
          </Flex>
        </Flex>
        <Flex
          as={motion.div}
          opacity={0}
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.2, duration: 0.2 },
            ...whileInView.heroNiloSkatingShadow,
          }}
          position={'absolute'}
          transition={{ type: 'linear' }} // Set the transition to linear
          top={`${205 - whileInView.heroNiloSkatingShadow.translateY}px`}
          left={`${145 - whileInView.heroNiloSkatingShadow.translateX}px`}
        >
          <Image
            loading={['lazy']}
            w={`${202}px`}
            h={`${260}px`}
            src={heroNiloSkatingShadow}
          />
        </Flex>
        <Flex
          as={motion.div}
          whileInView={{
            transition: { delay: 0.2, duration: 0.4 },
            ...whileInView.heroNiloSkating,
          }}
          initial={{ x: 0, y: 0 }}
          position={'absolute'}
          transition={{ type: 'linear' }} // Set the transition to linear
          top={`${205 + whileInView.heroNiloSkating.translateY}px`}
          left={`${40 + whileInView.heroNiloSkating.translateX}px`}
        >
          <Image w={`${336}px`} h={`${295}px`} src={heroNiloSkating} />
        </Flex>
      </MotionBox>
    </Box>
  );
};

const MotionBox = chakra(motion.div, {
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});
*/

export const HeroSection = () => {
  return (
    <>
      <Flex
        w={'100%'}
        mx={'auto'}
        pb={['0']}
        pt={['150px', '150px', '100px', '80px']}
        px={['2rem', '2rem', '2rem', '3rem']}
        justify={['center']}
        align={['center']}
        position={['relative']}
        bg={
          'linear-gradient(233.09deg, rgba(250, 190, 98, 0.41) 10.87%, #29B5BD 90.27%, #00ACA6 135.83%)'
        }
        bgSize={['contain', 'contain', 'cover', 'cover']}
      >
        <Flex
          maxW={'130em'}
          position={'relative'}
          direction={['column-reverse', 'column-reverse', 'row', 'row']}
          justify={['center', 'center', 'space-between', 'space-between']}
        >
          {/* image container 290 x 290 */}
          <Flex
            width={['100%', '100%', '60%', '40%']}
            gridArea={'img'}
            justify={['center', 'center', 'flex-end', 'flex-start']}
            mt={['0']}
          >
            {/* Grouped Image*/}
            <Flex
              align={['center', 'center', 'flex-end', 'center']}
              position={'relative'}
            >
              <Flex w={'100%'} minH={['400px', '500px']}>
                <Image loading={['lazy']} src={heroNiloSkatingMobile} />
              </Flex>
              {/* ImageContainer */}
            </Flex>
          </Flex>
          <Flex
            gridArea={'wor'}
            maxW={['unset', 'unset', '50%', '60%']}
            align={'center'}
          >
            <Flex
              direction={'column'}
              pb={['0', '0', '1rem', '6rem']}
              px={['0', '0', '1rem', '5rem']}
              maxW={['100%', '100%', '100%', '600px']}
            >
              <Heading
                as={'h1'}
                size={'4xl'}
                textAlign={['center', 'center', 'left', 'left']}
              >
                <FormattedMessage
                  id={'hero.heading'}
                  description={'hero heading'}
                  defaultMessage={'Brush Better.{br} Get Rewarded.'}
                  values={{ br: <br /> }}
                />
              </Heading>
              <Text
                mt={['20px']}
                color={'#464543'}
                align={['center', 'center', 'left', 'left']}
                size={'lg'}
                lineHeight={'taller'}
              >
                <FormattedMessage
                  id={'hero.description'}
                  description={'hero description'}
                  defaultMessage={
                    'Our goal is to improve our community’s oral health by making brushing and oral care a rewarding experience.'
                  }
                />
              </Text>
              <Flex
                mt={'20px'}
                direction={['column', 'column', 'row', 'column']}
                align={['center', 'center', 'flex-start', 'flex-start']}
              >
                <Link href={'https://app.nilo.gg'}>
                  <Button
                    variant={'solid'}
                    colorScheme={'primary'}
                    maxW={['200px']}
                    minW={['150px']}
                    onClick={() => track('claim')}
                  >
                    <FormattedMessage
                      id={'hero.action.button.1.label'}
                      description={'hero action button 1 label'}
                      defaultMessage={'Claim Here!'}
                    />
                  </Button>
                </Link>
                <Text
                  size={'sm'}
                  align={'left'}
                  mt={1}
                  ml={[0, 0, 4, 0]}
                  fontSize={'1em'}
                  fontWeight={500}
                  color={'#666666'}
                >
                  <FormattedMessage
                    id={'hero.comingsoon'}
                    description={'hero coming soon'}
                    defaultMessage={'Claim your Nilo and Toothbrush!'}
                  />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Hide breakpoint="(min-width: 120em)">
          <Box
            position={'absolute'}
            bottom={'-1px'}
            left={0}
            pointerEvents={['none']}
            w={['300px', '300px', '400px', '430px']}
            height={'296px'}
            bg={`url('${heroBambooFrame}') no-repeat`}
            bgPosition={[
              '-100px bottom',
              '-100px bottom',
              '-60px bottom',
              '-10px 10px',
            ]}
            bgSize={['contain', 'contain', 'contain', 'cover']}
          ></Box>
          <Box
            position={'absolute'}
            bottom={'-3px'}
            pointerEvents={['none']}
            w={'full'}
            height={'240px'}
            bg={`url('${heroFrontWaveFrame}') no-repeat`}
            bgPosition={'left bottom'}
            bgSize={['contain', 'contain', 'cover', 'cover']}
          />
        </Hide>
      </Flex>
    </>
  );
};
