import ArticleImage from './ArticleImage';

import { Box } from '@chakra-ui/react';
import React from 'react';
import { media } from 'src/utils/media';
import styled from 'styled-components';
import { TinaMarkdown } from 'tinacms/dist/rich-text';

//import ArticleImage from './ArticleImage';
//import Code from './Code';
//import Link from './Link';
//import Quote from './Quote';

export function FaqRichText({ content, ...rest }) {
  return (
    <Box {...rest}>
      <Container>
        <TinaMarkdown content={content} components={faqComponents} />
      </Container>
    </Box>
  );
}

export function RichText({ content, ...rest }) {
  return (
    <Box {...rest}>
      <Container>
        <TinaMarkdown content={content} components={components} />
      </Container>
    </Box>
  );
}

const Container = styled.div`
  display: flex;
  ${'' /* Opting-out of margin-collapse */}

  flex-direction: column;
  width: 100%;

  section:not(:last-child) {
    margin-bottom: 3.8rem;
  }

  a {
    word-break: break-word;
  }

  ${media('<=desktop')} {
    .remark-highlight {
      width: 100%;
      overflow-x: auto;
    }
  }

  & > section,
  .footnotes {
    ${'' /* content-visibility: auto; */}
  }

  ol,
  ul {
    font-size: 1.2rem;
    line-height: 1.9;
    margin: 0;
    padding-left: 2.4rem;
    color: #666666;
    li {
      & > * {
        vertical-align: top;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2.2rem;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.9;
  hanging-punctuation: first;
  color: #666666;

  &:not(:last-child) {
    margin-bottom: 2.2rem;
  }

  & + ul,
  & + li {
    margin-top: -1.5rem !important;
  }

  img {
    border-radius: 30px;
  }
`;

const FaqParagraph = styled(Paragraph)`
  font-size: 0.875rem;
  line-height: 2;

  a {
    text-decoration: underline;
  }

  & + ul,
  & + li {
    font-size: 0.875rem;
    margin-top: -1.5rem !important;
  }
`;

const SecondHeading = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.4;

  margin-bottom: 2rem;
`;

const ThirdHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1.5rem;
`;
const FourthHeading = styled.h4`
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 1.5rem;
`;

const FifthHeading = styled.h5`
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 1.5rem;
`;

const Break = styled.br`
  display: block;
  content: '';
  margin: 0;
  height: 3rem;
`;

/*const TextHighlight = styled.code`
  display: inline-block;
  padding: 0 0.6rem;
  color: rgb(var(--textSecondary));
  border-radius: 0.4rem;
  background-color: rgba(var(--primary), 0.8);
  font-size: 1.6rem;
  font-family: inherit;
`;*/

const CustomA = ({ title, url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {title}
    </a>
  );
};

const components = {
  h2: SecondHeading,
  h3: ThirdHeading,
  h4: FourthHeading,
  h5: FifthHeading,
  p: Paragraph,
  br: Break,
  img: ArticleImage,
};

const faqComponents = {
  ...components,
  p: FaqParagraph,
  a: CustomA,
};
