import { ButtonLink } from '../ButtonLink/ButtonLink';

import { Box, Flex, Heading, SimpleGrid, Text, Image } from '@chakra-ui/react';
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { Head } from 'src/components/Head/Head';

const niloImage = '/images/nilo-gloomy.png';

export const NotFound = () => {
  return (
    <Box h="full" w="full" position="relative" bg={'#f5f5f5'}>
      <Head
        title={'Page not Found'}
        subtitle={'404: This page could not be found.'}
      />
      <Flex
        h={'100vh'}
        w={'100%'}
        mx={'auto'}
        pb={['0']}
        pt={['150px', '150px', '100px', '90px']}
        px={['2rem', '2rem', '2rem', '3rem']}
        justify={['center']}
        align={['center']}
        position={['relative']}
        bg={
          'linear-gradient(230.76deg, rgba(250, 190, 98, 0.41) 14.53%, #29B5BD 91.06%, #00ACA6 134.97%)'
        }
        size={['cover']}
      >
        <SimpleGrid
          columns={[1, 2]}
          columnGap={[4]}
          maxW={['100%', '100%', '48em', '900px']}
        >
          <Flex display={['flex']} px={['2rem', '2rem']}>
            <Image loading={['lazy']} src={niloImage} />
          </Flex>
          <Flex w={['100%', '500px']}>
            <Flex
              px={['2rem']}
              py={['50px']}
              justify={['flex-start', 'center']}
              align={['center', 'flex-start']}
              textAlign={['center', 'left']}
              direction={['column']}
              w={'100%'}
            >
              <Heading size={'4xl'} color={'black'}>
                <FormattedMessage
                  id={'notfound.404.header'}
                  description={'notfound.404.header'}
                  defaultMessage={'Oops...'}
                />
              </Heading>
              <Text fontSize={'xl'} color={'black'} lineHeight={[1.6]}>
                <FormattedMessage
                  id={'notfound.404.description'}
                  description={'notfound.404.description'}
                  defaultMessage={'The page you are looking for is lost.'}
                />
              </Text>

              <ButtonLink
                to={'/'}
                variant={'solid'}
                colorScheme={'primary'}
                mt={['20px', '40px']}
                minW={'unset'}
                w={['fit-content']}
                py={['10px']}
                px={['15px']}
                pr={['20px']}
                leftIcon={<FiArrowLeft size={12} />}
              >
                <FormattedMessage
                  id={'login.form.button.submit'}
                  description={'login page login'}
                  defaultMessage={'Back Home'}
                />
              </ButtonLink>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Box>
  );
};
