import {
  Box,
  Flex,
  Link as ChakraLink,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { whitepaperUrl, supportUrl, blogUrl } from 'src/config';
import { Logo } from 'src/Logo';

const footerbg = '/images/FooterBG.png';

export const Footer = () => {
  const { formatMessage } = useIntl();

  const FOOTER_ITEMS = [
    [
      {
        title: formatMessage({
          id: 'nav.item.theapp',
          description: 'nav item theapp',
          defaultMessage: 'The App',
        }),
        href: '/#theapp',
        isExternal: false,
      },
      {
        title: formatMessage({
          id: 'nav.item.nilo',
          description: 'nav item nilo',
          defaultMessage: 'Nilo',
        }),
        href: '/#nilo',
        isExternal: false,
      },
      {
        title: formatMessage({
          id: 'nav.item.nlttoken',
          description: 'nav item nlttoken',
          defaultMessage: 'NLT Token',
        }),
        href: '/#token',
        isExternal: false,
      },
      {
        title: formatMessage({
          id: 'nav.item.blog',
          description: 'nav item blog',
          defaultMessage: 'Blog',
        }),
        href: blogUrl,
        isExternal: true,
      },
    ],
    [
      {
        title: formatMessage({
          id: 'nav.item.nilostore',
          description: 'nav item nilostore',
          defaultMessage: 'Nilo Store',
        }),
        href: '', //'https://shopnilo.com',
        isExternal: true,
      },
      {
        title: formatMessage({
          id: 'nav.item.marketplace',
          description: 'nav item marketplace',
          defaultMessage: 'Marketplace',
        }),
        href: '', //'/auction',
        isExternal: false,
      },
      {
        title: formatMessage({
          id: 'nav.item.myaccount',
          description: 'nav item myaccount',
          defaultMessage: 'My Account',
        }),
        href: '', //PAGE_URLS['myaccount'],
        isExternal: false,
      },
    ],
    [
      {
        title: formatMessage({
          id: 'nav.item.whitepaper',
          description: 'nav item whitepaper',
          defaultMessage: 'Whitepaper',
        }),
        href: whitepaperUrl,
        isExternal: true,
      },
      {
        title: formatMessage({
          id: 'nav.item.support',
          description: 'nav item support',
          defaultMessage: 'Support',
        }),
        href: supportUrl,
        isExternal: true,
      },
      {
        title: formatMessage({
          id: 'nav.item.privacy',
          description: 'nav item privacy',
          defaultMessage: 'Privacy',
        }),
        href: '/privacy',
        isExternal: true,
      },
      {
        title: formatMessage({
          id: 'nav.item.terms',
          description: 'nav item terms',
          defaultMessage: 'Terms',
        }),
        href: '/terms',
        isExternal: true,
      },
    ],
  ];

  return (
    <Box bg={['#f5f5f5']}>
      <Box
        w={'100%'}
        pt={[0, '10rem']}
        pb={['60px', '5rem']}
        px={'2rem'}
        position={'relative'}
        bgImage={['#f5f5f5', `url(${footerbg})`]}
        bgRepeat={'no-repeat'}
        bgSize={'cover'}
      >
        <SimpleGrid
          maxW={['100%', '100%', '48em', '950px']}
          mx={'auto'}
          columnGap={[6]}
          columns={[1, 4]}
        >
          <Flex direction={'column'} align={['center', 'flex-start']}>
            <Logo />
            <Text
              mt={[3]}
              textAlign={['center', 'left']}
              lineHeight={'base'}
              color={'#1A4B46'}
              fontSize={'lg'}
              fontFamily={'Poppins'}
              as={'i'}
            >
              {'Brush Better.'}
              <br />
            </Text>
          </Flex>
          {FOOTER_ITEMS.map((navColumn, index) => {
            return (
              <Stack
                key={index}
                spacing={[4]}
                orientation={'vertical'}
                display={['none', 'flex']}
              >
                {navColumn.map(navItem =>
                  navItem.isExternal ? (
                    <ChakraLink
                      key={navItem.title}
                      href={navItem.href}
                      isExternal
                    >
                      {navItem.title}
                    </ChakraLink>
                  ) : (
                    <ChakraLink
                      key={navItem.title}
                      as={navItem.href.indexOf('/#') != -1 ? HashLink : Link}
                      to={navItem.href}
                    >
                      {navItem.title}
                    </ChakraLink>
                  )
                )}
              </Stack>
            );
          })}
        </SimpleGrid>
        <Flex position={'absolute'} right={0} bottom={0} my={[1]} mx={[4, 8]}>
          <Text>&copy; Copyright 2023 Nilo</Text>
        </Flex>
        <script>
          <link
            rel="stylesheet"
            href="https://sibforms.com/forms/end-form/build/sib-styles.css"
          />
          <script defer src="/sib.js"></script>
        </script>
      </Box>
    </Box>
  );
};
