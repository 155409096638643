import * as React from 'react';
import storage from 'src/utils/storage';

async function loadLocaleData(locale) {
  switch (locale) {
    case 'en':
      return import('src/compiled-lang/en.json');
    default:
      return import('src/compiled-lang/en.json');
  }
}
export const useLocale = (
  initial = {
    current: storage.getLocale() || 'en',
    currentMsgs: { default: {} },
  }
) => {
  const [{ current, currentMsgs }, setCurrent] = React.useState(initial);

  const setLocale = React.useCallback(async val => {
    const messages = await loadLocaleData(val);
    setCurrent({ current: val, currentMsgs: messages });
  }, []);

  return { locale: current, messages: currentMsgs, setLocale };
};
