import { Image } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';

export default function ArticleImage({ url, src, caption, ...rest }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image
          src={url || src}
          alt={caption || 'Article Image'}
          placeholder="blur"
          fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPkj6+vBwAC4AFuNSmtGAAAAABJRU5ErkJggg=="
          loading="lazy"
          fit="cover"
          {...rest}
        />
      </ImageWrapper>
    </Wrapper>
  );
}

const ImageWrapper = styled.div`
  position: relative;
  max-width: 90rem;
  border-radius: 0.6rem;
  overflow: hidden;

  &::before {
    padding-top: 56.25%;
    content: '';
  }

  &::after {
    display: block;
    content: '';
    clear: both;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;
//
//const Caption = styled.small`
//  display: block;
//  font-size: 1.4rem;
//  text-align: center;
//  margin-top: 1rem;
//`;
