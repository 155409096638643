export function gql(strings, ...args) {
  let str = "";
  strings.forEach((string, i) => {
    str += string + (args[i] || "");
  });
  return str;
}
export const Informational_PagePartsFragmentDoc = gql`
    fragment Informational_pageParts on Informational_page {
  title
  slug
  body
  created_date
}
    `;
export const Featured_BlogPartsFragmentDoc = gql`
    fragment Featured_blogParts on Featured_blog {
  blog_post {
    ... on Post {
      title
      description
      is_published
      date
      created_date
      blog_category {
        ... on Blog_category {
          label
          slug
          description
          is_active
        }
        ... on Document {
          id
        }
      }
      category
      tags
      heroImage
      imageUrl
      body
    }
    ... on Document {
      id
    }
  }
  created_date
}
    `;
export const Blog_CategoryPartsFragmentDoc = gql`
    fragment Blog_categoryParts on Blog_category {
  label
  slug
  description
  is_active
}
    `;
export const FaqPartsFragmentDoc = gql`
    fragment FaqParts on Faq {
  title
  is_published
  is_home
  body
  created_date
}
    `;
export const PostPartsFragmentDoc = gql`
    fragment PostParts on Post {
  title
  description
  is_published
  date
  created_date
  blog_category {
    ... on Blog_category {
      label
      slug
      description
      is_active
    }
    ... on Document {
      id
    }
  }
  category
  tags
  heroImage
  imageUrl
  body
}
    `;
export const Informational_PageDocument = gql`
    query informational_page($relativePath: String!) {
  informational_page(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...Informational_pageParts
  }
}
    ${Informational_PagePartsFragmentDoc}`;
export const Informational_PageConnectionDocument = gql`
    query informational_pageConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: Informational_pageFilter) {
  informational_pageConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...Informational_pageParts
      }
    }
  }
}
    ${Informational_PagePartsFragmentDoc}`;
export const Featured_BlogDocument = gql`
    query featured_blog($relativePath: String!) {
  featured_blog(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...Featured_blogParts
  }
}
    ${Featured_BlogPartsFragmentDoc}`;
export const Featured_BlogConnectionDocument = gql`
    query featured_blogConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: Featured_blogFilter) {
  featured_blogConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...Featured_blogParts
      }
    }
  }
}
    ${Featured_BlogPartsFragmentDoc}`;
export const Blog_CategoryDocument = gql`
    query blog_category($relativePath: String!) {
  blog_category(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...Blog_categoryParts
  }
}
    ${Blog_CategoryPartsFragmentDoc}`;
export const Blog_CategoryConnectionDocument = gql`
    query blog_categoryConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: Blog_categoryFilter) {
  blog_categoryConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...Blog_categoryParts
      }
    }
  }
}
    ${Blog_CategoryPartsFragmentDoc}`;
export const FaqDocument = gql`
    query faq($relativePath: String!) {
  faq(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...FaqParts
  }
}
    ${FaqPartsFragmentDoc}`;
export const FaqConnectionDocument = gql`
    query faqConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: FaqFilter) {
  faqConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...FaqParts
      }
    }
  }
}
    ${FaqPartsFragmentDoc}`;
export const PostDocument = gql`
    query post($relativePath: String!) {
  post(relativePath: $relativePath) {
    ... on Document {
      _sys {
        filename
        basename
        breadcrumbs
        path
        relativePath
        extension
      }
      id
    }
    ...PostParts
  }
}
    ${PostPartsFragmentDoc}`;
export const PostConnectionDocument = gql`
    query postConnection($before: String, $after: String, $first: Float, $last: Float, $sort: String, $filter: PostFilter) {
  postConnection(
    before: $before
    after: $after
    first: $first
    last: $last
    sort: $sort
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ... on Document {
          _sys {
            filename
            basename
            breadcrumbs
            path
            relativePath
            extension
          }
          id
        }
        ...PostParts
      }
    }
  }
}
    ${PostPartsFragmentDoc}`;
export function getSdk(requester) {
  return {
    informational_page(variables, options) {
      return requester(Informational_PageDocument, variables, options);
    },
    informational_pageConnection(variables, options) {
      return requester(Informational_PageConnectionDocument, variables, options);
    },
    featured_blog(variables, options) {
      return requester(Featured_BlogDocument, variables, options);
    },
    featured_blogConnection(variables, options) {
      return requester(Featured_BlogConnectionDocument, variables, options);
    },
    blog_category(variables, options) {
      return requester(Blog_CategoryDocument, variables, options);
    },
    blog_categoryConnection(variables, options) {
      return requester(Blog_CategoryConnectionDocument, variables, options);
    },
    faq(variables, options) {
      return requester(FaqDocument, variables, options);
    },
    faqConnection(variables, options) {
      return requester(FaqConnectionDocument, variables, options);
    },
    post(variables, options) {
      return requester(PostDocument, variables, options);
    },
    postConnection(variables, options) {
      return requester(PostConnectionDocument, variables, options);
    }
  };
}
import { createClient } from "tinacms/dist/client";
const generateRequester = (client) => {
  const requester = async (doc, vars, _options) => {
    const data = await client.request({
      query: doc,
      variables: vars
    });
    return { data: data?.data, query: doc, variables: vars || {} };
  };
  return requester;
};
export const ExperimentalGetTinaClient = () => getSdk(
  generateRequester(createClient({ url: "http://localhost:4001/graphql", queries }))
);
export const queries = (client) => {
  const requester = generateRequester(client);
  return getSdk(requester);
};
