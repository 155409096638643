import { InfoBitContainer } from './InfoBitContainer';

import { Flex, Heading, Text, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const savingsIcon = '/images/IconSavings.png';
const ecosystemIcon = '/images/IconEcosystem.png';
const governanceIcon = '/images/IconGovernance.png';

export const TokenUtilitySection = () => {
  const { formatMessage } = useIntl();

  return (
    <Flex
      id={'token'}
      w={'100%'}
      mx={'auto'}
      py={['20px', '80px']}
      pb={['20px', '50px']}
      px={['2rem', '2rem', '2rem', '0']}
      pt={['20px', '100px']}
      justify={['center', 'space-between']}
      align={['center']}
      position={['relative']}
      direction={'column'}
    >
      <Flex direction={'column'} maxW={['100%', '950px']}>
        <Flex
          direction={'column'}
          px={['0', '0']}
          py={['50px', '0']}
          justify={['center']}
        >
          <Heading as={'h2'} size={'3xl'} pr={[0]} textAlign={['center']}>
            <FormattedMessage
              id={'tokenutility.heading'}
              description={'tokenutility tokenutility heading'}
              defaultMessage={'The NLT Token'}
            />
          </Heading>
          <Text mt={['20px']} align={['center']} fontSize={'md'}>
            <FormattedMessage
              id={'tokenutility.description'}
              description={'tokenutility tokenutility description'}
              defaultMessage={
                'NLT is the utility and governance token of the Nilo Ecosystem, which can be earned by following healthy brushing habits.'
              }
            />
          </Text>
        </Flex>
      </Flex>
      <SimpleGrid
        maxW={['100%', '700px']}
        columns={[1]}
        mt={[2, 14]}
        columnGap={[0, 4]}
        rowGap={[2, 14]}
      >
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.tokensavings.title',
            description: 'small bits tokensavings title',
            defaultMessage: 'Savings',
          })}
          description={formatMessage({
            id: 'smallbits.tokensavings.description',
            description: 'small bits tokensavings description',
            defaultMessage:
              'Users of the Nilo application can use their earned NLT to receive significant discounts on oral care products and avail special offers in the Nilo Store.',
          })}
          height={36}
          width={41}
          top={-5}
          src={savingsIcon}
          alt={'savings icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.tokenecosystem.title',
            description: 'small bits tokenecosystem title',
            defaultMessage: 'Ecosystem',
          })}
          description={formatMessage({
            id: 'smallbits.tokenecosystem.description',
            description: 'small bits tokenecosystem description',
            defaultMessage:
              'The NLT token is the Nilo ecosystem’s designated currency, used for purchasing digital items, leveling up, and participating in exclusive auctions.',
          })}
          height={36}
          width={41}
          top={-5}
          src={ecosystemIcon}
          alt={'ecosystem icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.tokengovernance.title',
            description: 'small bits tokengovernance title',
            defaultMessage: 'Governance',
          })}
          description={formatMessage({
            id: 'smallbits.tokengovernance.description',
            description: 'small bits tokengovernance description',
            defaultMessage:
              'The Nilo community will govern the Ecosystem Treasury, which funds campaigns to grow the Nilo Ecosystem, using the NLT token to incentivize and align stakeholder interests.',
          })}
          height={37}
          width={36}
          top={-6}
          src={governanceIcon}
          alt={'governance icon'}
        />
      </SimpleGrid>
    </Flex>
  );
};
