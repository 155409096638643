const storagePrefix = 'nilo_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: token => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  setCountry: country => {
    window.localStorage.setItem(
      `${storagePrefix}country`,
      JSON.stringify(country)
    );
  },
  getCountry: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}country`));
  },
  setLocale: locale => {
    window.localStorage.setItem(
      `${storagePrefix}locale`,
      JSON.stringify(locale)
    );
  },
  getLocale: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}locale`));
  },
  setLineNonce: nonce => {
    window.localStorage.setItem(
      `${storagePrefix}linenonce`,
      JSON.stringify(nonce)
    );
  },
  getLineNonce: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}linenonce`));
  },
};

export default storage;
