import {
  Flex,
  Box,
  Button,
  Input,
  Heading,
  Text,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import { FormattedMessage, useIntl } from 'react-intl';
import Container from 'src/components/Container/Container';
import { sibUrl } from 'src/config';
import { media } from 'src/utils/media';
import styled from 'styled-components';

const newsletterNiloImage = '/images/NewsletterImage.png';

// eslint-disable-next-line no-useless-escape
const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export function NewsletterSection({ onOpen }) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isError, setIsError] = useState(false);
  const { formatMessage } = useIntl();

  const handleInputChange = e => {
    e.preventDefault();
    isError && setIsError(false);
    setEmail('');
  };

  const handleSubmit = async () => {
    if (regex.test(email)) {
      // send
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('EMAIL', email);
        formData.append('email_address_check', '');
        formData.append('locale', 'en');

        await fetch(sibUrl, {
          method: 'POST',
          body: formData,
        });
        setIsLoading(false);
        setIsSuccessful(true);
        console.log('Form data submitted successfully');
      } catch (error) {
        console.error('Error submitting form data', error);
        setIsLoading(false);
        setIsError(true);
      }
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };

  console.log(handleSubmit);

  return (
    <SectionContainer>
      <ContentContainer>
        <Box display="none">
          <Box id={'sib-form-container'}>
            <Box id="error-message">
              <Text className="sib-form-message-panel__text sib-form-message-panel__text--center"></Text>
            </Box>

            <Box id="success-message" className="sib-form-message-panel">
              <Text className="sib-form-message-panel__text sib-form-message-panel__text--center"></Text>
            </Box>
          </Box>
        </Box>
        <HeadingContainer>
          <Heading color={'black'} size={['xl', '3xl']} textAlign={['left']}>
            <FormattedMessage
              id={'newsletter.title'}
              description={'newsletter title'}
              defaultMessage={'Want to stay updated?'}
            />
          </Heading>
          <Text
            mt={[2, 0]}
            textAlign={['left']}
            fontSize={['sm', 'lg']}
            pr={['5rem', '10rem', '10rem', '10rem', 0]}
          >
            <FormattedMessage
              id={'newsletter.subtitle'}
              description={'newsletter subtitle'}
              defaultMessage={
                'Subscribe to our newsletter to receive the latest updates '
              }
            />
          </Text>
        </HeadingContainer>
        <FormRow id={'sib-form'}>
          <Flex
            //className={'sib-form-container sib-container'}
            direction={['column']}
            justify={['flex-start']}
            w={['100%', '100%', '100%', '100%', '60%']}
            mr={['10px']}
          >
            <FormControl
              w={'100'}
              //className={'sib-input sib-form-block'}
              isInvalid={isError}
            >
              <Box minH={['50px']} className={'form__entry entry_block'}>
                <Input
                  w={['100%']}
                  borderRadius={['10px']}
                  minH={['50px']}
                  _placeholder={{
                    color: '#9B9B9B',
                  }}
                  mt={[0, 0, 0, 4, 4]}
                  //className={'input entry__field'}
                  type="email"
                  bg={'white'}
                  id={'EMAIL'}
                  name={'EMAIL'}
                  onClick={onOpen}
                  onChange={handleInputChange}
                  value={email}
                  placeholder={formatMessage({
                    id: 'newsletter.email.placeholder',
                    description: 'newsletter email placeholder',
                    defaultMessage: 'Enter your email address',
                  })}
                  required
                />
                <FormErrorMessage
                  display={'none'}
                  className={'sib-form-message-panel'}
                >
                  <Text id="success-message" className={'entry__error'}></Text>
                  <Text id="error-message" className={'entry__error'}></Text>
                </FormErrorMessage>
              </Box>
            </FormControl>
          </Flex>
          <Button
            form="sib-form"
            minW={['100%', '130px']}
            minH={['50px']}
            h={['50px']}
            variant={'solid'}
            boxSizing={['border-box']}
            colorScheme={'primary'}
            mt={[4, 4, 4, 4, 4]}
            leftIcon={isSuccessful && <FiCheck />}
            isDisabled={isSuccessful}
            onClick={onOpen}
            isLoading={isLoading}
            _hover={{
              bg: 'primary.800',
            }}
            _active={{
              bg: 'primary.400',
            }}
          >
            <Flex>
              <Text color={'white'}>
                {isSuccessful ? (
                  <FormattedMessage
                    id={'newsletter.button.label.success'}
                    description={'newsletter button label success'}
                    defaultMessage={'Subscribed'}
                  />
                ) : (
                  <FormattedMessage
                    id={'newsletter.button.label'}
                    description={'newsletter button label'}
                    defaultMessage={'Subscribe'}
                  />
                )}
              </Text>
            </Flex>
          </Button>
        </FormRow>
      </ContentContainer>
    </SectionContainer>
  );
}

const FormRow = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-right: 3rem;
  margin-top: 15px;

  ${media('<=desktop')} {
    padding-right: inherit;
    flex-direction: column;
    margin-bottom: 15px;
    margin-top: 0;
  }
`;

const HeadingContainer = styled.div`
  ${media('<=desktop')} {
    padding-bottom: 35px;
    background: url(${newsletterNiloImage}) no-repeat;
    background-size: 125px;
    background-position-y: bottom;
    background-position-x: right;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  max-width: 570px;

  ${media('<=desktop')} {
    width: 100%;
    margin: 0;
    text-align: center;
  }
`;

const SectionContainer = styled(Container)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 920px;
  padding-top: 50px;
  height: 300px;
  background: url(${newsletterNiloImage}) no-repeat;
  background-size: 360px;
  background-position-y: bottom;
  background-position-x: right;
  margin-top: 20px;
  margin-bottom: -33px;

  ${media('<=desktop')} {
    margin-top: 0;
    padding-top: 30px;
    flex-direction: column;
    background: none;
    background-size: contain;
    background-position: bottom;
    margin-bottom: 30px;
    align-items: center;
  }
`;
