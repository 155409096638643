import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

import { createBrowserRouter } from 'react-router-dom';
import {
  //BlogRoutes,
  routes as blogRoutes,
} from 'src/features/blog/routes';
import { routes as landingRoutes } from 'src/features/landing/routes';

const commonRoutes = [
  //{ path: '/blog/*', element: <BlogRoutes /> },
  ...blogRoutes,
  ...landingRoutes,
];

export const router = createBrowserRouter([
  ...publicRoutes,
  ...protectedRoutes,
  ...commonRoutes,
]);
