import { client } from '../../../../.tina/__generated__/client';

import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { json, useLoaderData } from 'react-router-dom';
import { Layout } from 'src/components/Layout/Layout';
import { RichText } from 'src/components/MDXRichText/MDXRichText';

export const loader = async () => {
  const infoPageResponse = await client.queries.informational_page({
    relativePath: `Terms.mdx`,
  });
  return json({ data: infoPageResponse.data }, { status: 200 });
};

export const Terms = () => {
  const data = useLoaderData();
  const pageInfo = data.data?.informational_page;
  return (
    <Layout title={'Terms of Use'}>
      <Box bg={'#F5F5F5'} p={[8, 28]} py={[20, 20, 100]} minH={'100vh'}>
        <Heading size={'4xl'} textAlign={['left']}>
          {'Terms of Use'}
        </Heading>
        <RichText mt={'40px'} content={pageInfo?.body} />
      </Box>
    </Layout>
  );
};
