import { client } from '../../../../.tina/__generated__/client';

import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { json, useLoaderData } from 'react-router-dom';
import { Layout } from 'src/components/Layout/Layout';
import { RichText } from 'src/components/MDXRichText/MDXRichText';

function pathToTitle(path) {
  return path
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');
}

export const loader = async ({ params }) => {
  const infoPageResponse = await client.queries.informational_page({
    relativePath: `${pathToTitle(params?.pageName)}.mdx`,
  });
  console.log('infor pge resp ', infoPageResponse); //{ slug: { eq: 'privacy' } }
  return json({ data: infoPageResponse.data }, { status: 200 });
};

export const MiscInformation = () => {
  const data = useLoaderData();
  const pageInfo = data.data?.informational_page;
  return (
    <Layout title={pageInfo?.title || 'Information'}>
      <Box bg={'#F5F5F5'} p={[8, 28]} py={[20, 20, 100]} minH={'100vh'}>
        <Heading size={'4xl'} textAlign={['left']}>
          {pageInfo?.title}
        </Heading>
        <RichText mt={'40px'} content={pageInfo?.body} />
      </Box>
    </Layout>
  );
};
