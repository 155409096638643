import { Button, Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const ButtonLink = ({ children, to, href = null, ...rest }) => {
  if (href != null) {
    // external link
    return (
      <ChakraLink href={href} isExternal>
        <Button
          _hover={{ cursor: 'pointer' }}
          variant={'outline'}
          colorScheme={'brand'}
          fontWeight={[400]}
          {...rest}
        >
          {children}
        </Button>
      </ChakraLink>
    );
  } else {
    return (
      <Link to={to}>
        <Button
          _hover={{ cursor: 'pointer' }}
          variant={'outline'}
          colorScheme={'brand'}
          fontWeight={[400]}
          {...rest}
        >
          {children}
        </Button>
      </Link>
    );
  }
};
