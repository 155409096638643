import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
//const alertTypes = ['info', 'success', 'warning', 'error'];
const MotionAlert = motion(Alert);

export const NotificationItem = ({
  notification: { id, type, title, message, timeout },
  onDismiss,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(id);
    }, timeout || 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <MotionAlert
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      status={type}
      paddingLeft={18}
      paddingRight={[10, 50]}
      borderRadius={4}
    >
      <AlertIcon />
      <AlertTitle mr={3}>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
      <CloseButton
        onClick={() => {
          onDismiss(id);
        }}
        position="absolute"
        right="8px"
        top="8px"
      />
    </MotionAlert>
  );
};
