import { Box, Flex } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { Spinner } from 'src/components/Elements/Spinner/Spinner';
import { useUser } from 'src/lib/auth';
import { lazyImport } from 'src/utils/lazyImport';

export function RequireAuth({ children }) {
  console.log('why does this run?');
  const user = useUser();
  const location = useLocation();
  console.log('check if redirect neesd Redirect');
  if (!user.data) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to="/auth/login" state={{ from: location }} replace={true} />
    );
  }

  return children;
}

const { MyAccountRoutes } = lazyImport(
  () => import('src/features/myaccount'),
  'MyAccountRoutes'
);

const App = () => {
  return (
    <Box h={'100vh'} w={'100vw'}>
      <Suspense
        fallback={
          <Flex align={'center'} justify={'center'} w={'full'} h={'full'}>
            <Spinner size="xl" />
          </Flex>
        }
      >
        <Outlet />
      </Suspense>
    </Box>
  );
};

export const protectedRoutes = [
  {
    path: '/app/*',
    element: <App />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth>
            <MyAccountRoutes />
          </RequireAuth>
        ),
      },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
