import { NotificationItem } from './NotificationItem';

import { VStack, Center } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useNotificationAlertsStore } from 'src/stores/notificationAlerts';

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationAlertsStore();

  return (
    <Center w="full" position={'absolute'}>
      <VStack
        width={['100%', '20%']}
        spacing={[3, 6]}
        zIndex="10"
        marginTop={15}
        mx={3}
      >
        <AnimatePresence>
          {notifications.map(notification => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onDismiss={dismissNotification}
            />
          ))}
        </AnimatePresence>
      </VStack>
    </Center>
  );
};
