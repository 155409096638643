import React from 'react';
export function Logo({ ...rest }) {
  return (
    <svg
      id="logo-34"
      width="80"
      height="36"
      viewBox="0 0 64 26"
      fill="none"
      color="#1A4B46"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.39582 12.5111C1.83857 12.5111 -0.329521 11.3291 0.0421116 7.80949C0.417904 4.23009 5.03835 -0.521584 15.8448 0.0534285L19.4336 14.6012C19.5029 14.3926 21.4865 0.0555144 21.4865 0.0555144H27.6892L24.0998 25.9999H17.3799L13.5249 11.4473L11.4712 25.9999H5.10699L8.39136 3.76007C8.39136 3.76007 4.72149 2.09135 2.43692 6.66016"
        fill="currentColor"
      />
      <path
        d="M3.36035 12.5112C5.05172 12.5112 6.42285 11.1362 6.42285 9.44009C6.42285 7.74395 5.05172 6.36896 3.36035 6.36896C1.66898 6.36896 0.297852 7.74395 0.297852 9.44009C0.297852 11.1362 1.66898 12.5112 3.36035 12.5112Z"
        fill="currentColor"
      />
      <path
        d="M34.2787 5.61801C35.8257 5.61801 37.0798 4.36038 37.0798 2.80901C37.0798 1.25764 35.8257 0 34.2787 0C32.7316 0 31.4775 1.25764 31.4775 2.80901C31.4775 4.36038 32.7316 5.61801 34.2787 5.61801Z"
        fill="currentColor"
      />
      <path
        d="M39.0295 20.0566C37.3807 23.0311 34.4929 25.0043 34.0027 20.7575C33.4314 15.8028 37.2219 7.32776 37.2219 7.32776H31.2592C31.2592 7.32776 26.9902 19.0345 29.2367 23.3314C31.4443 27.5533 36.2526 26.3178 39.7949 22.3017"
        fill="currentColor"
      />
      <path
        d="M49.0984 20.3028C47.4087 23.1438 44.5522 25.0127 44.1209 20.7596C43.1939 11.6539 48.2671 0.0236816 48.2671 0.0236816H42.312C42.312 0.0236816 37.3199 19.0345 39.5691 23.3315C41.7767 27.5533 46.5851 26.3178 50.1273 22.3017"
        fill="currentColor"
      />
      <path
        d="M56.691 6.79029C52.6232 6.36477 49.0088 9.07504 48.3321 15.5865C47.6568 22.0806 50.6347 25.4973 54.7025 25.9228C58.7704 26.3484 62.3702 23.7737 63.0615 17.1259C63.7756 10.2557 60.7582 7.21512 56.691 6.79029ZM58.3696 16.6378C57.8073 22.0486 56.3631 24.2993 54.887 24.147C53.4108 23.9948 52.4734 21.3658 53.0226 16.0816C53.5731 10.7771 55.0291 8.41799 56.5059 8.57234C57.9827 8.7267 58.9506 11.0462 58.3696 16.6378Z"
        fill="currentColor"
      />
    </svg>
  );
}
