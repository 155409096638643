import { Image, Link, Heading, Box } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Container from 'src/components/Container/Container';
import { SOCIALS } from 'src/config';
import { media } from 'src/utils/media';
import styled from 'styled-components';

const joinCommunityBg = '/images/JoinCommunityBg.svg';
const joinCommunityBgMobile = '/images/JoinCommunityBg-m.svg';
const twitterIcon = '/images/icon-twitter.svg';
const discordIcon = '/images/icon-discord.svg';
//const fbIcon = '/images/icon-fb.svg';
//const igIcon = '/images/icon-ig.svg';
//const telegramIcon = '/images/icon-telegram.svg';
const twitterIconAlt = '/images/icon-twitter-alt.svg';
const discordIconAlt = '/images/icon-discord-alt.svg';

export const DiscordIcon = ({ boxSize = '50px', alt = false }) => (
  <IconContainer>
    <Link href={SOCIALS['discord']} isExternal>
      <Image
        loading={['lazy']}
        src={alt ? discordIconAlt : discordIcon}
        alt={'discord'}
        height={boxSize}
        boxSize={boxSize}
      />
    </Link>
  </IconContainer>
);

export const TwitterIcon = ({ boxSize = '50px', alt = false }) => (
  <IconContainer>
    <Link href={SOCIALS['twitter']} isExternal>
      <Image
        loading={['lazy']}
        src={alt ? twitterIconAlt : twitterIcon}
        alt={'twitter'}
        height={boxSize}
        boxSize={boxSize}
      />
    </Link>
  </IconContainer>
);

export function JoinCommunitySection() {
  return (
    <Box w={['100%']} px={['1rem', 0]} py={[0]}>
      <SectionContainer>
        <TitleContainer>
          <Heading
            color={'black'}
            size={['2xl', '3xl', '3xl']}
            textAlign={['center', 'left']}
          >
            <FormattedMessage
              id={'joincommunity.title'}
              description={'joincommunity title'}
              defaultMessage={'Join Our Community'}
            />
          </Heading>
        </TitleContainer>
        <ContentContainer>
          <DiscordIcon />
          <TwitterIcon />
          {/*<IconContainer>
            <Image
              loading={['lazy']}
              src={telegramIcon}
              alt={'telegram'}
              height={'50px'}
              boxSize={'50px'}
            />
          </IconContainer>
          <IconContainer>
            <Image
              loading={['lazy']}
              src={igIcon}
              alt={'ig'}
              height={'50px'}
              boxSize={'50px'}
            />
          </IconContainer>
          <IconContainer>
            <Image
              loading={['lazy']}
              src={fbIcon}
              alt={'fb'}
              height={'50px'}
              boxSize={'50px'}
            />
  </IconContainer>*/}
        </ContentContainer>
      </SectionContainer>
    </Box>
  );
}

const TitleContainer = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;

  ${media('<=tablet')} {
    font-size: 20px;
    width: 100%;
    text-align: center;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const IconContainer = styled.div`
  margin-right: 60px;

  ${media('<=tablet')} {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  ${media('<=desktop')} {
    > img {
      max-width: 80%;
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  width: 60%;
  display: flex;
  justify-content: center;

  ${media('<=tablet')} {
    width: 85%;
    margin: 0 auto;
    margin-top: 10px;
    text-align: center;
    justify-content: center;
  }
`;

const SectionContainer = styled(Container)`
  display: flex;
  justify-content: center;
  max-width: 960px;
  min-height: 300px;
  align-items: center;
  background: url(${joinCommunityBg}) no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 0 !important;

  ${media('<=tablet')} {
    flex-direction: column;
    background: url(${joinCommunityBgMobile}) no-repeat;
    background-size: contain;
    background-position-y: center;
    margin-top: 50px;
    padding-bottom: 0px !important;
    padding-top: 0px;
    background-position-x: center;
    min-height: 250px;
  }
`;
