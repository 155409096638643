import { BlogRedirect } from './Blog';
//import { Blog, blogPageLoader } from './Blog';
//import { BlogEntry, getBlogDetails } from './BlogEntry';

import React from 'react';
//import { NotFound } from 'src/components/Layout/NotFound';

export const routes = [
  {
    path: '/blog',
    element: <BlogRedirect />,
  },
  /*{
    element: <Blog />,
    path: '/blog',
    loader: blogPageLoader,
    errorElement: <NotFound />,
  },
  {
    id: 'blogEntry',
    element: <BlogEntry />,
    path: '/blog/:blogTitle',
    loader: getBlogDetails,
    errorElement: <NotFound />,
  },*/
];
