import { Landing, landingLoader } from './Landing';
import { MiscInformation, loader as miscLoader } from './MiscInformation';
import { Privacy, loader as privacyLoader } from './Privacy';
import { Terms, loader as termsLoader } from './Terms';

import { React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from 'src/components/Layout/NotFound';
//import { lazyImport } from 'src/utils/lazyImport';

//const { Landing } = lazyImport(() => import('./Landing'), 'Landing');

export const routes = [
  {
    element: <MiscInformation />,
    loader: miscLoader,
    path: 'misc/:pageName',
    errorElement: <NotFound />,
  },
  {
    element: <Privacy />,
    loader: privacyLoader,
    path: 'privacy',
  },
  {
    element: <Terms />,
    loader: termsLoader,
    path: 'terms',
  },
  {
    element: <Landing />,
    path: '',
    index: true,
    loader: landingLoader,
    errorElement: <NotFound />,
  },
  {
    element: <NotFound />,
    path: '*',
  },
];

export const LandingRoutes = () => {
  return (
    <Routes>
      <Route
        path="misc/:pageName"
        element={<MiscInformation />}
        errorElement={<NotFound />}
      />
      <Route path="privacy" element={<Privacy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="" index={true} element={<Landing />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
