import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { ChakraProvider, Flex, Heading, Button } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics } from '@vercel/analytics/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import { Spinner } from 'src/components/Elements/Spinner/Spinner';
import { Notifications } from 'src/components/Notifications/Notifications';
import { theme } from 'src/config/theme';
import { useLocale } from 'src/hooks/locale';
import { queryClient } from 'src/lib/react-query';
import { router } from 'src/routes';

window?.amplitude.init('f3fda11994785cd790afe46b886a927e', undefined, {
  defaultTracking: {
    sessions: true,
    pageViews: true,
    formInteractions: true,
    fileDownloads: true,
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-WJSQ4QZ',
};

TagManager.initialize(tagManagerArgs);

const ErrorFallback = () => {
  return (
    <Flex
      color={'red'}
      width={'full'}
      height={'full'}
      direction={'column'}
      justify={'center'}
      align={'center'}
      role="alert"
    >
      <Heading as={'h2'} className="text-lg font-semibold">
        {'Ooops, something went wrong : ('}{' '}
      </Heading>
      <Button
        mt={4}
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </Button>
    </Flex>
  );
};

export const AppProvider = () => {
  const { locale, messages } = useLocale();
  return (
    <React.Suspense
      fallback={
        <Flex
          align={'center'}
          justify={'center'}
          minW={'full'}
          minH={'full'}
          mt={['40px', '100px']}
        >
          <Spinner
            w={'50px'}
            h={'50px'}
            thickness={'10px'}
            speed={'0.7s'}
            emptyColor={'#efefef'}
            color={'#1A4B46'}
          />
        </Flex>
      }
    >
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={messages?.default}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Analytics />
          <HelmetProvider>
            <ChakraProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
                <Notifications />
                <RouterProvider
                  router={router}
                  fallbackElement={
                    <Flex
                      align={'center'}
                      justify={'center'}
                      w={'100vw'}
                      h={'100vh'}
                    >
                      <Spinner />
                    </Flex>
                  }
                />
              </QueryClientProvider>
            </ChakraProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </IntlProvider>
    </React.Suspense>
  );
};
