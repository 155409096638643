import { InfoBitContainer } from './InfoBitContainer';

import {
  Flex,
  Heading,
  Text,
  Image,
  SimpleGrid,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const earnAppScreen = '/images/Earn_appscreen.png';
const earnDrip1 = '/images/Earn_drip1.png';
const earnDrip2 = '/images/Earn_drip2.png';
const earnDrip3 = '/images/Earn_drip3.png';
const earnGiftSmall = '/images/EarnGiftSmall.png';
const earnGiftStar = '/images/Earn_giftstar.png';
const earnGiftDot = '/images/Earn_giftdot.png';
const earnPopoutNlt = '/images/Earn_nltwallet.png';
const earnPopoutRewards = '/images/Earn_RewardsPop.png';
const earnPopoutEarnings = '/images/Earn_XPEarnings.png';

const giftIcon = '/images/IconNFTGift.png';
const brushStoreIcon = '/images/IconBrushStore.png';
const tokenIcon = '/images/IconNLTToken.png';

const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const initView = {
  earnPopoutNlt: {
    scale: 0.9,
  },
  earnPopoutEarnings: {
    scale: 0.9,
  },
  earnPopoutRewards: {
    scale: 0.9,
  },
  earnDrip1: {
    scaleY: 0.2,
  },
  earnDrip2: {
    scaleY: 0.2,
  },
  earnDrip3: {
    scaleY: 0.2,
  },
};

const durationMin = 0.4;
const durationCoeff = 0.5;
const delayCoeff = 0.0;

const whileInView = {
  earnDrip1: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
  earnDrip2: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
  earnDrip3: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
  earnPopoutNlt: {
    scale: 1.0,
    transition: {
      delay: 0.2,
      duration: 0.3,
    },
  },
  earnPopoutEarnings: {
    scale: 1.0,
    transition: {
      delay: 0.4,
      duration: 0.6,
    },
  },
  earnPopoutRewards: {
    scale: 1.0,
    transition: {
      delay: 0.6,
      duration: 0.9,
    },
  },
};

export const EarnSection = () => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      maxW={['100%', '100%', '48em', '950px']}
      w={'100%'}
      mx={'auto'}
      pb={['0', '20px', '0']}
      pt={['80px']}
      px={['2rem', '2rem', '1rem', '0']}
      justify={['center', 'center', 'space-between']}
      align={['center']}
      position={['relative']}
      bg={'#F5F5F5'}
      direction={'column'}
    >
      <Flex
        position={'relative'}
        direction={['column-reverse', 'column-reverse', 'row-reverse']}
        justify={['center', 'center', 'space-between']}
      >
        <Flex
          width={['100%', '100%', '55%', '55%']}
          maxW={['unset', 'unset', '55%', '55%']}
          align={'center'}
        >
          <Flex
            direction={'column'}
            maxW={['100%', '100%', '850px']}
            px={['0']}
            pl={['0', '0', '2rem']}
            py={['50px', '50px', '0']}
          >
            <Heading
              as={'h2'}
              size={'3xl'}
              color={'black'}
              pr={[0, 0, '30px']}
              textAlign={['center', 'center', 'left']}
            >
              <FormattedMessage
                id={'earnsection.heading'}
                description={'earn section heading'}
                defaultMessage={'Brush to earn Tokens and Rewards'}
              />
            </Heading>
            <Text
              mt={['20px']}
              align={['center', 'center', 'left']}
              fontSize={'md'}
            >
              <FormattedMessage
                id={'earnsection.description'}
                description={'earn section description'}
                defaultMessage={
                  'On a weekly basis, the Nilo platform will automatically distribute NLT tokens based on the global earned BXP from active brushing.'
                }
              />
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={['100%', '100%', '45%', '45%']}
          transform={['', '', 'scale(1.1)', 'scale(1.2)']}
          py={['', '', '0.5rem', '1rem']}
          justify={['center', 'center', 'center']}
        >
          <MotionBox
            w={273}
            h={297}
            display={'flex'}
            position={'relative'}
            alignItems={'center'}
            justifyContent={['center']}
          >
            <Flex
              position={'relative'}
              w={`${273}px`}
              h={`${297}px`}
              justify={['center', 'flex-start']}
            >
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.earnDrip2}
                whileInView={whileInView.earnDrip2}
                position={'absolute'}
                top={`${199}px`}
                left={`${41}px`}
              >
                <Image
                  loading={['lazy']}
                  w={`${10}px`}
                  h={`${91}px`}
                  src={earnDrip2}
                />
              </Flex>
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.earnDrip1}
                whileInView={whileInView.earnDrip1}
                position={'absolute'}
                top={`${161}px`}
                left={`${25}px`}
              >
                <Image
                  loading={['lazy']}
                  src={earnDrip1}
                  w={`${10}px`}
                  h={`${91}px`}
                />
              </Flex>
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.earnDrip3}
                whileInView={whileInView.earnDrip3}
                position={'absolute'}
                top={`${91}px`}
                left={`${257}px`}
              >
                <Image
                  loading={['lazy']}
                  src={earnDrip3}
                  w={`${6}px`}
                  h={`${38}px`}
                />
              </Flex>
              <Flex position={'relative'} w={'100%'}>
                <Image
                  loading={['lazy']}
                  position={'absolute'}
                  top={0}
                  left={0}
                  src={earnAppScreen}
                />
              </Flex>

              <Flex
                as={motion.div}
                initial={initView.earnPopoutNlt}
                whileInView={whileInView.earnPopoutNlt}
                position={'absolute'}
                left={`${140}px`}
                top={`${23}px`}
              >
                <Image
                  loading={['lazy']}
                  src={earnPopoutNlt}
                  w={`${68}px`}
                  h={`${28}px`}
                />
              </Flex>
              <Flex
                as={motion.div}
                initial={initView.earnPopoutRewards}
                whileInView={whileInView.earnPopoutRewards}
                position={'absolute'}
                left={`${18}px`}
                top={`${180}px`}
              >
                <Image
                  loading={['lazy']}
                  src={earnPopoutRewards}
                  w={`${91}px`}
                  h={`${54}px`}
                />
              </Flex>
              <Flex
                as={motion.div}
                initial={initView.earnPopoutEarnings}
                whileInView={whileInView.earnPopoutEarnings}
                position={'absolute'}
                left={`${149}px`}
                top={`${125}px`}
              >
                <Image
                  loading={['lazy']}
                  src={earnPopoutEarnings}
                  w={`${76}px`}
                  h={`${41}px`}
                />
              </Flex>

              <Flex position={'absolute'} left={`${0}px`} top={`${110}px`}>
                <Image
                  loading={['lazy']}
                  src={earnGiftDot}
                  w={`${65}px`}
                  h={`${66}px`}
                />
              </Flex>
              <Flex position={'absolute'} left={`${130}px`} top={`${178}px`}>
                <Image
                  loading={['lazy']}
                  src={earnGiftStar}
                  w={`${108}px`}
                  h={`${131}px`}
                />
              </Flex>
              <Flex position={'absolute'} left={`${103}px`} top={`${228}px`}>
                <Image
                  loading={['lazy']}
                  src={earnGiftSmall}
                  w={`${70}px`}
                  h={`${72}px`}
                />
              </Flex>
            </Flex>
          </MotionBox>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={[1, 1, 3]}
        mt={[2, 2, 14]}
        columnGap={[0]}
        rowGap={[2, 6, 0]}
      >
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.nlttoken.title',
            description: 'small bits nlttoken title',
            defaultMessage: 'NLT Token',
          })}
          description={formatMessage({
            id: 'smallbits.nlttoken.description',
            description: 'small bits nlttoken description',
            defaultMessage:
              'User our in-game token for leveling up, purchasing items, and much more',
          })}
          height={36}
          width={40}
          top={-2}
          left={-5}
          src={tokenIcon}
          alt={'token icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.nftrewards.title',
            description: 'small bits nftrewards title',
            defaultMessage: 'NFT Rewards',
          })}
          description={formatMessage({
            id: 'smallbits.nftrewards.description',
            description: 'small bits nftrewards description',
            defaultMessage:
              'Earn gear and item rewards that can help you earn even more points.',
          })}
          height={55}
          width={53}
          top={-16}
          left={-10}
          src={giftIcon}
          alt={'gift icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.brushstore.title',
            description: 'small bits brushstore title',
            defaultMessage: 'Nilo Store',
          })}
          description={formatMessage({
            id: 'smallbits.brushstore.description',
            description: 'small bits brushstore description',
            defaultMessage:
              'Redeem tokens to save big on dental care essentials like brush heads & more.',
          })}
          height={41}
          width={37}
          top={-3.5}
          src={brushStoreIcon}
          alt={'brush store icon'}
        />
      </SimpleGrid>
    </Flex>
  );
};
