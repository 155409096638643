import { client } from '../../../../.tina/__generated__/client';
import { BrushEvolveSection } from '../components/BrushEvolveSection';
import { BrushSection } from '../components/BrushSection';
import { EarnSection } from '../components/EarnSection';
import { FaqSection } from '../components/FaqSection';
import { GetaBrushSection } from '../components/GetaBrushSection';
import { HeroSection } from '../components/HeroSection';
import { JoinCommunitySection } from '../components/JoinCommunitySection';
import { MeetNiloSection } from '../components/MeetNiloSection';
import { NewsletterSection } from '../components/NewsletterSection';
import { TokenUtilitySection } from '../components/TokenUtilitySection';
import { WaitlistModal } from '../components/WaitlistModal';

import { Box, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { defer } from 'react-router-dom';
import { Layout } from 'src/components/Layout/Layout';

export const fetchFAQs = async () => {
  const resp = await client.queries.faqConnection({
    filter: {
      is_published: { eq: true },
    },
    sort: 'created_date',
    first: 10,
  });
  const data = resp.data.faqConnection.edges.map(x => {
    return {
      id: x?.node?.id,
      title: x?.node?.title,
      body: x?.node?.body,
    };
  });
  return data;
};

export const landingLoader = async () => {
  return defer({
    faqData: fetchFAQs(),
  });
};

export const Landing = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Layout title={'Nilo'}>
      <Box bg={'#F5F5F5'} pb={['50px', '100px']}>
        <WaitlistModal isOpen={isOpen} onClose={onClose} />
        <HeroSection />
        <BrushSection />
        <EarnSection />
        <MeetNiloSection />
        <BrushEvolveSection />
        <TokenUtilitySection />
        <FaqSection />
        <GetaBrushSection onOpen={onOpen} />
        <NewsletterSection onOpen={onOpen} />
        <JoinCommunitySection />
      </Box>
    </Layout>
  );
};
