import {
  useDisclosure,
  Flex,
  Link,
  chakra,
  Square,
  HStack,
  Button,
  Box,
  VStack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link as LinkReact } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
//import DocumentIcon from 'src/assets/icons/DocumentIcon';
import {
  PAGE_URLS,
  whitepaperUrl,
  supportUrl,
  appUrl as defaultAppUrl,
  shopUrl,
  blogUrl,
  //iosUrl,
  //androidUrl,
} from 'src/config';
import {
  DiscordIcon,
  TwitterIcon,
} from 'src/features/landing/components/JoinCommunitySection';
import { useScrollDirection } from 'src/hooks/useScrollDirection';
import { Logo } from 'src/Logo';

export const Navbar = () => {
  const bg = 'rgba(255,255,255,0.0)';
  //const mobileBg = '#1A4B46';
  const { formatMessage } = useIntl();
  const scrollDirection = useScrollDirection();
  const mobileNav = useDisclosure();

  const MOBILE_NAV_ITEMS = [
    {
      title: formatMessage({
        id: 'nav.item.theapp',
        description: 'nav item theapp',
        defaultMessage: 'App Features',
      }),
      href: '/#theapp',
      isExternal: false,
    },
    {
      title: formatMessage({
        id: 'nav.item.nilo',
        description: 'nav item nilo',
        defaultMessage: 'Nilo',
      }),
      href: '/#nilo',
      isExternal: false,
    },
    {
      title: formatMessage({
        id: 'nav.item.nlttoken',
        description: 'nav item nlttoken',
        defaultMessage: 'NLT Token',
      }),
      href: '/#token',
      isExternal: false,
    },
    {
      title: formatMessage({
        id: 'nav.item.blog',
        description: 'nav item blog',
        defaultMessage: 'Blog',
      }),
      href: PAGE_URLS['blog'] + '/#',
      isExternal: false,
    },
    {
      title: formatMessage({
        id: 'nav.item.whitepaperbutton',
        defaultMessage: 'Whitepaper',
      }),
      href: whitepaperUrl,
      isExternal: true,
    },
    {
      title: formatMessage({
        id: 'nav.item.shopbutton',
        defaultMessage: 'Get Brush',
      }),
      href: shopUrl,
      isExternal: true,
    },
  ];

  const MOBILE_SUB_NAV_ITEMS = [
    {
      title: formatMessage({
        id: 'nav.item.support',
        description: 'nav item support',
        defaultMessage: 'Support',
      }),
      href: supportUrl,
      isExternal: true,
    },
    {
      title: formatMessage({
        id: 'nav.item.privacy',
        description: 'nav item privacy',
        defaultMessage: 'Privacy',
      }),
      href: '/privacy',
      isExternal: true,
    },
    {
      title: formatMessage({
        id: 'nav.item.terms',
        description: 'nav item terms',
        defaultMessage: 'Terms',
      }),
      href: '/terms',
      isExternal: true,
    },
  ];

  let appUrl = defaultAppUrl; // default is app.nilo.gg (web)

  /*if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    appUrl = iosUrl;
  } else if (/Android/i.test(window.navigator.userAgent)) {
    appUrl = androidUrl;
  }*/

  return (
    <React.Fragment>
      <chakra.header
        as={motion.div}
        zIndex={1}
        position={'sticky'}
        top={scrollDirection === 'down' ? '-90px' : 0}
        transition="0.5s cubic-bezier(0.4, 0, 0.2, 1)"
        h={'90px'}
        mt={'-90px'}
        bg={bg}
        w="full"
        px={{
          base: 2,
          sm: 4,
        }}
        py={4}
      >
        <Flex
          alignItems="center"
          h={'full'}
          justifyContent="space-between"
          mx="auto"
          px={[4, 4, 20]}
        >
          <Flex>
            <chakra.a
              href="/"
              title="Nilo NLT"
              display="flex"
              alignItems="center"
            >
              <Logo />
            </chakra.a>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={8}
              mr={8}
              color="brand.500"
              display={{
                base: 'none',
                md: 'inline-flex',
              }}
            >
              <ChakraLink as={HashLink} smooth={'true'} to={'/#theapp'}>
                <FormattedMessage
                  id={'navbar.item.theapp'}
                  description={'navbar item theapp'}
                  defaultMessage={'App Features'}
                />
              </ChakraLink>
              <ChakraLink as={HashLink} smooth={'true'} to={'/#nilo'}>
                <FormattedMessage
                  id={'navbar.item.nilo'}
                  description={'navbar item nilo'}
                  defaultMessage={'Nilo'}
                />
              </ChakraLink>
              <ChakraLink as={HashLink} smooth={'true'} to={'/#token'}>
                <FormattedMessage
                  id={'navbar.item.nlttoken'}
                  description={'navbar item nlttoken'}
                  defaultMessage={'NLT Token'}
                />
              </ChakraLink>
              <ChakraLink
                as={LinkReact}
                smooth={'true'}
                to={blogUrl}
                isExternal
              >
                <FormattedMessage
                  id={'navbar.item.blog'}
                  description={'navbar item blog'}
                  defaultMessage={'Blog'}
                />
              </ChakraLink>
              <ChakraLink
                as={LinkReact}
                smooth={'true'}
                to={whitepaperUrl}
                isExternal
              >
                <FormattedMessage
                  id={'navbar.item.whitepaperbutton'}
                  description={'navbar item whitepaper button'}
                  defaultMessage={'Whitepaper'}
                />
              </ChakraLink>
              <ChakraLink
                as={LinkReact}
                smooth={'true'}
                to={shopUrl}
                isExternal
              >
                <FormattedMessage
                  id={'navbar.item.shopbutton'}
                  description={'navbar item shop button'}
                  defaultMessage={'Get Brush'}
                />
              </ChakraLink>
            </HStack>
            <Link href={appUrl}>
              <Button
                variant={'solid'}
                colorScheme={'primary'}
                minH={'40px'}
                borderRadius={'10px'}
                fontWeight={500}
              >
                <FormattedMessage
                  id={'navbar.item.useappbutton'}
                  description={'navbar item use app button'}
                  defaultMessage={'Use App'}
                />
              </Button>
            </Link>

            <Box
              display={{
                base: 'inline-flex',
                md: 'none',
              }}
            >
              <IconButton
                display={{
                  base: 'flex',
                  md: 'none',
                }}
                aria-label="Open menu"
                fontSize="20px"
                color="gray.800"
                _dark={{
                  color: 'inherit',
                }}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />

              <Drawer
                onClose={mobileNav.onClose}
                isOpen={mobileNav.isOpen}
                size={'full'}
                blockScrollOnMount={true}
                trapFocus={false}
              >
                <DrawerOverlay />
                <DrawerContent bg={'#F5F5F5'} py={'40px'}>
                  <DrawerCloseButton h={'40px'} w={'40px'} m={'18px 14px'} />
                  <DrawerHeader>
                    <Flex justify={'center'}>
                      <Logo />
                    </Flex>
                  </DrawerHeader>
                  <DrawerBody mt={'30px'}>
                    <VStack spacing={[8]} fontSize={'1.2rem'}>
                      {MOBILE_NAV_ITEMS.map((item, ind) =>
                        item.isExternal ? (
                          <ChakraLink
                            key={ind}
                            href={item.href}
                            onClick={mobileNav.onClose}
                            isExternal
                          >
                            {item.title}
                          </ChakraLink>
                        ) : (
                          <ChakraLink
                            key={ind}
                            as={
                              item.href.indexOf('/#') != -1
                                ? HashLink
                                : LinkReact
                            }
                            smooth={'true'}
                            to={item.href}
                            onClick={mobileNav.onClose}
                          >
                            {item.title}
                          </ChakraLink>
                        )
                      )}
                    </VStack>
                  </DrawerBody>
                  <DrawerFooter pb={'50px'} justifyContent={'center'}>
                    <VStack spacing={[6]} w={'full'}>
                      <HStack
                        justify={'center'}
                        align={'center'}
                        spacing={'20px'}
                      >
                        <Square borderRadius={'20px'} bg={'#1A4B46'} p={'14px'}>
                          <DiscordIcon boxSize={'30px'} alt={true} />
                        </Square>
                        <Square borderRadius={'20px'} bg={'#1A4B46'} p={'14px'}>
                          <TwitterIcon boxSize={'30px'} alt={true} />
                        </Square>
                      </HStack>
                      <HStack
                        w={'full'}
                        justify={'center'}
                        spacing={[8]}
                        fontSize={'0.9rem'}
                      >
                        {MOBILE_SUB_NAV_ITEMS.map((item, ind) =>
                          item.isExternal ? (
                            <ChakraLink
                              key={ind}
                              href={item.href}
                              onClick={mobileNav.onClose}
                              isExternal
                            >
                              {item.title}
                            </ChakraLink>
                          ) : (
                            <ChakraLink
                              key={ind}
                              as={
                                item.href.indexOf('/#') != -1
                                  ? HashLink
                                  : LinkReact
                              }
                              smooth={'true'}
                              to={item.href}
                              onClick={mobileNav.onClose}
                            >
                              {item.title}
                            </ChakraLink>
                          )
                        )}
                      </HStack>
                    </VStack>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
};
