import { axios } from 'src/lib/axios';

//export type RegisterCredentialsDTO = {
//  email: string;
//  password: string;
//  firstName: string;
//  lastName: string;
//};

export const registerWithEmailAndPassword = data => {
  return axios.post('/auth/register', data);
};
