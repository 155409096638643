import { client } from '../../../../.tina/__generated__/client';

import { Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { json, useLoaderData } from 'react-router-dom';
import { Layout } from 'src/components/Layout/Layout';
import { RichText } from 'src/components/MDXRichText/MDXRichText';

export const loader = async () => {
  const infoPageResponse = await client.queries.informational_page({
    relativePath: `Privacy.mdx`,
  });
  console.log('infor pge resp ', infoPageResponse); //{ slug: { eq: 'privacy' } }
  return json({ data: infoPageResponse.data }, { status: 200 });
};

export const Privacy = () => {
  const data = useLoaderData();
  const pageInfo = data.data?.informational_page;
  return (
    <Layout title={'Privacy'}>
      <Box bg={'#F5F5F5'} p={[8, 28]} py={[20, 20, 100]} minH={'100vh'}>
        <Heading size={'4xl'} textAlign={['left']}>
          {'Privacy Policy'}
        </Heading>
        <RichText mt={'40px'} content={pageInfo?.body} />
      </Box>
    </Layout>
  );
};
