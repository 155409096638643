import { Button, Box, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ShoppingBagIcon from 'src/assets/icons/ShoppingBagIcon';
import Container from 'src/components/Container/Container';
import { appUrl as defaultAppUrl, iosUrl, androidUrl } from 'src/config';
import { media } from 'src/utils/media';
import styled from 'styled-components';

const getaBrushBg = '/images/get-a-brush-bg.png';
const getaBrushBgMobile = '/images/get-a-brush-bg-m.png';

export function GetaBrushSection() {
  const onOpen = () => {
    let appUrl = defaultAppUrl; // default is app.nilo.gg (web)

    if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
      appUrl = iosUrl;
    } else if (/Android/i.test(window.navigator.userAgent)) {
      appUrl = androidUrl;
    }
    window.location = appUrl;
  };
  return (
    <Box w={['100%']} px={['1rem', '1rem', 0]} py={['50px', '50px', 0]}>
      <SectionContainer>
        <GroupedImageContainer>
          <GroupedImage>
            <ImageContainer></ImageContainer>
          </GroupedImage>
        </GroupedImageContainer>
        <ContentContainer>
          <Heading
            size={['sm', 'sm', '3xl', '3xl']}
            color={'black'}
            textAlign={['left', 'left', 'left', 'center']}
          >
            <FormattedMessage
              id={'getbrush.title'}
              description={'getbrush title'}
              defaultMessage={'Get Started with Nilo'}
            />
          </Heading>
          <Button
            w={'100%'}
            maxW={['150px', '150px', '230px']}
            minH={['34px', '34px', '50px']}
            h={['20px', '20px', '50px']}
            mt={[2, 2, 4]}
            px={[2, 2, 4]}
            justifySelf={'center'}
            variant={'solid'}
            colorScheme={'primary'}
            onClick={onOpen}
          >
            <ButtonContent>
              <ShoppingBagIcon />
              <Text
                ml={[2, 2, 4]}
                color={'white'}
                fontSize={['sm', 'sm', 'md']}
              >
                <FormattedMessage
                  id={'getbrush.button.label'}
                  description={'getbrush button label'}
                  defaultMessage={'Get our App'}
                />
              </Text>
            </ButtonContent>
          </Button>
        </ContentContainer>
      </SectionContainer>
    </Box>
  );
}
const GroupedImage = styled(motion.div)`
  position: relative;
  width: 394px;
  justify-content: flex-start;

  & > div {
    position: absolute;
  }

  ${media('<=tablet')} {
    justify-content: center;
    width: 300px;
    height: 10rem;
  }
`;

const GroupedImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-size: contain;
  background-position-y: bottom;

  ${media('<=desktop')} {
    width: fit-content;
    justify-content: center;
    background-position-x: center;
    height: 600px;
    max-width: 60%;
    padding-bottom:194px;

  ${media('<tablet')} {
    width: fit-content;
    justify-content: center;
    background-position-x: center;
    max-height: 300px;
    max-width: 40%;
    padding-bottom:0;
    height: unset;
  }
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 20px;
    width: 20px;
  }
  ${media('<tablet')} {
    svg {
      height: 14px;
      width: 14px;
    }
  }
`;

const ImageContainer = styled(motion.div)`
  flex: 1;

  position: relative;
  background-position-y: 0;
  background-position-x: left;

  bottom: 60px;

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  ${media('<=desktop')} {
    bottom: 0px;
    top: 0;
  }

  ${media('<tablet')} {
    bottom: 0px;
    top: 0;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 140px;
  max-width: 660px;
  margin-left: 60px;

  ${media('<desktop')} {
    margin-left: 1rem;
    padding-right: 40px;
  }

  ${media('<tablet')} {
    width: 100%;
    margin: 0;
    margin-left: 1rem;
    text-align: center;
    padding-top: 0px;
    padding-right: 3rem;
  }
`;

const SectionContainer = styled(Container)`
  display: flex;
  justify-content: center;
  max-width: 960px;
  min-height: 550px;
  align-items: center;
  background: url(${getaBrushBg}) no-repeat;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
  padding-bottom: 35px;
  padding-right: 50px;
  padding-left: 50px;

  ${media('<desktop')} {
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    min-height: 220px;
    padding: 0;
    background: url(${getaBrushBg}) no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    padding-bottom: 0;
  }

  ${media('<tablet')} {
    margin: 0 auto;
    max-width: 425px;
    min-height: 200px;
    padding: 0;
    background: url(${getaBrushBgMobile}) no-repeat;
    background-size: contain;
    background-position-x: center;
    padding-bottom: 0;
  }
  @media (max-width: 390px) {
    min-height: 170px;
  }
`;
