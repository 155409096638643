import { client } from '../../../../.tina/__generated__/client';

import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Button,
  Skeleton,
  Tab,
  Tabs,
  TabList,
  Image,
  Heading,
  AspectRatio,
  useToken,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
//import { IoMdShare } from 'react-icons/io';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  defer,
  Await,
  Link,
  useLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { Spinner } from 'src/components/Elements/Spinner/Spinner';
import { Layout } from 'src/components/Layout/Layout';
import { blogUrl } from 'src/config';
import {
  findIndexByValue,
  createObjectByPropertyName,
} from 'src/utils/objects';
import { getQueryParams } from 'src/utils/urls';
const blogBgMobile = '/images/blog-background-m.svg';
const blogBg = '/images/blog-background.png';

dayjs.extend(LocalizedFormat);

function createBlogUrl(relativePath) {
  if (!relativePath) {
    return '/blog';
  }
  return `/blog/${(relativePath || '').replace('.mdx', '')}`;
}

export const fetchBlogListFromTag = async (tagQuery = 'all') => {
  //https://tina.io/docs/data-fetching/overview/
  // in eq
  const postResponse =
    tagQuery != 'all'
      ? await client.queries.postConnection({
          filter: {
            category: { eq: `${tagQuery}` },
            is_published: { eq: true },
            date: { before: new Date() },
          },
          sort: 'category-date',
          last: 10,
        })
      : await client.queries.postConnection({
          filter: {
            is_published: { eq: true },
            date: { before: new Date() },
          },
          sort: 'date',
          last: 10,
        });
  const data = postResponse.data.postConnection.edges.map(x => {
    return {
      title: x?.node?.title,
      id: x?.node?.id,
      description: x?.node?.description,
      body: x?.node?.body,
      imageUrl: x?.node?.imageUrl,
      heroImage: x?.node?.heroImage,
      date: x?.node?.date,
      tags: x?.node?.tags,
      created_date: x?.node?.created_date,
      relativePath: x?.node?._sys?.relativePath,
    };
  });
  return data;
};

export const fetchBlogCategories = async () => {
  const categoryResponse = await client.queries.blog_categoryConnection({
    filter: { is_active: { eq: true } },
    sort: 'label',
  });
  const data = categoryResponse.data.blog_categoryConnection.edges.map(x => {
    return {
      id: x?.node?.id,
      label: x?.node?.label,
      slug: x?.node?.slug,
      description: x?.node?.description,
    };
  });
  return data;
};

export const fetchFeaturedBlog = async () => {
  const postResponse = await client.queries.featured_blogConnection({
    sort: 'created_date',
    last: 1,
  });
  const data = postResponse.data.featured_blogConnection.edges.map(x => {
    return {
      title: x?.node?.blog_post?.title,
      id: x?.node?.blog_post?.id,
      description: x?.node?.blog_post?.description,
      body: x?.node?.blog_post?.body,
      imageUrl: x?.node?.blog_post?.imageUrl,
      heroImage: x?.node?.blog_post?.heroImage,
      date: x?.node?.blog_post?.date,
      tags: x?.node?.blog_post?.tags,
      created_date: x?.node?.blog_post?.created_date,
      relativePath: x?.node?.blog_post?.id.replace('content/posts/', ''),
    };
  });
  return data[0] || {};
};

export const blogPageLoader = async request => {
  // speed up query
  // https://stackoverflow.com/questions/74719956/can-i-handle-multiple-loaders-for-a-single-url-in-remix
  const queryParams = getQueryParams(request.request?.url);

  // get featured article
  //const featuredData = await fetchFeaturedBlog();
  // get categories data
  //const categoryData = await fetchBlogCategories();
  // get blog posts data for category
  //const blogPostsData = await fetchBlogListFromTag(queryParams['tag']);
  // do in parallel
  //const [featuredData, categoryData] = await Promise.all([
  //  fetchFeaturedBlog(),
  //  fetchBlogCategories(),
  //]);

  // return combined data.
  // defer loads the page first then data gets loaded after in <Await resolve={...} />.
  // unless await is used, this will fetch the data in a defer before load
  return defer({
    featuredBlog: await fetchFeaturedBlog(),
    categories: fetchBlogCategories(),
    // keep blog posts separately loading
    blogPosts: fetchBlogListFromTag(queryParams['tag']),
  });
};

const BlogFilters = ({ categories }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatMessage } = useIntl();
  const [bg700] = useToken('colors', ['primary.700']);
  // add default all tab
  const tabList = [
    { slug: 'all', label: 'All', description: '' },
    ...categories,
  ];

  // translate categories with defaultMessage as category's label
  const translatedCategories = defineMessages(
    createObjectByPropertyName(tabList, 'slug', ({ slug, label }) => ({
      id: `blog.category.label.${slug}`,
      description: `blog category label ${slug}`,
      defaultMessage: label,
    }))
  );

  // fetch index of tab list from given category's slug (tag)
  const tabIndex = findIndexByValue(
    tabList,
    'slug',
    (searchParams.get('tag') || 'all').toLowerCase()
  );

  // sets the query parameter and triggers a fetch for blogs in category
  const handleChange = index => {
    searchParams.set('tag', tabList[index].slug);
    setSearchParams(searchParams);
  };

  return (
    <Tabs
      variant="soft-rounded"
      colorScheme="green"
      pb={'20px'}
      mt={'20px'}
      index={tabIndex}
      onChange={index => handleChange(index)}
    >
      <TabList flexWrap={'wrap'} gridGap={2}>
        {tabList.map(({ slug }) => {
          return (
            <Tab
              key={slug}
              borderRadius={'10px'}
              border={`1px solid ${bg700}`}
              fontWeight={400}
              lineHeight={'18px'}
              py={['14px']}
              _focus={'none'}
              color={'primary'}
              textTransform={'capitalize'}
              _selected={{ color: 'white', bgColor: bg700 }}
            >
              {formatMessage(translatedCategories[slug])}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
};

const BlogListItem = ({ item }) => {
  return (
    <Link to={createBlogUrl(item?.relativePath)}>
      <Flex
        _hover={{ transform: 'translateY(-2px)' }}
        direction={'column'}
        borderRadius={'30px'}
        bg={'white'}
      >
        <AspectRatio ratio={426 / 300}>
          <Image
            loading={'lazy'}
            src={item?.heroImage || item?.imageUrl}
            maxW={'100%'}
            borderTopLeftRadius={'30px'}
            borderTopRightRadius={'30px'}
          />
        </AspectRatio>
        <Box py={[8, 6]} px={['2rem', 10]}>
          <Heading size={'xl'} color={'black'} lineHeight={1.4}>
            {item?.title}
          </Heading>
          <Flex mt={['8px', 0]}>
            <Text color={'brand'} fontSize={['sm']} mr={[5]}>
              {dayjs(item?.date || item?.created_date).format('ll')}
            </Text>
            <Text color={'brand'} fontSize={['sm']}>
              {/*getReadTime(item?.body)*/}
            </Text>
          </Flex>
          <Text noOfLines={[2]} mt={['10px', '5px']} lineHeight={1.6}>
            {item?.description}
          </Text>
        </Box>
      </Flex>
    </Link>
  );
};

export const BlogRedirect = () => {
  React.useEffect(() => {
    window.location = blogUrl;
  }, []);

  return (
    <Flex
      align={'center'}
      justify={'center'}
      minW={'full'}
      minH={'full'}
      mt={['40px', '100px']}
    >
      <Spinner
        w={'50px'}
        h={'50px'}
        thickness={'10px'}
        speed={'0.7s'}
        emptyColor={'#efefef'}
        color={'#1A4B46'}
      />
    </Flex>
  );
};

export const Blog = () => {
  // Router fetches main blog data with useLoaderData.
  // will have to fetch blog list within componentblogs
  const { featuredBlog, categories, blogPosts } = useLoaderData();

  return (
    <Layout title={'Nilo Blog. Stay on top of the Latest News and Insights!'}>
      <Box
        bgImage={[blogBgMobile, blogBg]}
        bgRepeat={['no-repeat']}
        px={['2rem']}
        bgSize={['contain', 'contain', 'contain', 'contain']}
        pb={'100px'}
      >
        <Flex
          w={'100%'}
          mx={'auto'}
          maxW={['100%', '100%', '48em', '1100px']}
          pt={['100px', '150px']}
          direction={'column'}
        >
          <Heading
            mt={['30px']}
            size={'xl'}
            color={'black'}
            textTransform={'uppercase'}
          >
            <FormattedMessage
              id={'blog.page.heading'}
              description={'blog.page.overtitle'}
              defaultMessage={'Blog'}
            />
          </Heading>
          <Heading size={'3xl'}>
            <FormattedMessage
              id={'blog.page.heading'}
              description={'blog.page.heading'}
              defaultMessage={'Stay on top of the Latest News and Insights!'}
            />
          </Heading>
          <Flex
            mt={['30px']}
            w={'100%'}
            justify={['center']}
            align={['center']}
          >
            <Link to={createBlogUrl(featuredBlog?.relativePath)}>
              <SimpleGrid
                maxW={'100%'}
                w={['100%']}
                minH={['480px']}
                borderRadius={'30px'}
                templateColumns={['100%', '540px 1fr']}
                columnGap={0}
                bg={'#ffffff'}
              >
                <AspectRatio ratio={540 / 480}>
                  <Image
                    borderTopLeftRadius={['30px', '30px']}
                    borderTopRightRadius={['30px', '0px']}
                    borderBottomLeftRadius={['0px', '30px']}
                    borderBottomRightRadius={['0px', '0px']}
                    loading={'lazy'}
                    maxW={'100%'}
                    src={featuredBlog.imageUrl}
                    alt={'mainblogimage'}
                  />
                </AspectRatio>
                <Flex
                  direction={'column'}
                  justify={'center'}
                  py={['50px']}
                  px={['2rem', '4rem']}
                  pr={['1rem', '4rem']}
                >
                  <Heading
                    size={['2xl', '3xl']}
                    lineHeight={[1.4, 1.2]}
                    pr={['1rem', '4rem']}
                    textTransform={['capitalize']}
                  >
                    {featuredBlog.title}
                  </Heading>
                  <Flex>
                    <Text color={'brand'} fontSize={'sm'}>
                      {dayjs(
                        featuredBlog.date || featuredBlog.created_date
                      ).format('ll')}
                    </Text>
                    <Text ml={[6]} color={'brand'} fontSize={'sm'}>
                      {/*getReadTime(featuredBlog.body)*/}
                    </Text>
                  </Flex>
                  <Text mt={['20px']}>{featuredBlog.description}</Text>
                  <Flex align={'center'} mt={'20px'}>
                    <Button mr={[5]}>
                      <FormattedMessage
                        id={'blog.page.readmore.label'}
                        description={'blog.page.readmore.label'}
                        defaultMessage={'Read More'}
                      />
                    </Button>
                    {/*<IoMdShare size={'25px'} />*/}
                  </Flex>
                </Flex>
              </SimpleGrid>
            </Link>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          mt={['60px']}
          mx={'auto'}
          maxW={['100%', '100%', '48em', '1100px']}
        >
          <Heading size={'2xl'} color={'black'}>
            <FormattedMessage
              id={'blog.page.latest.heading'}
              description={'blog.page.latest.heading'}
              defaultMessage={'Latest News'}
            />
          </Heading>
          <React.Suspense
            fallback={
              <Flex pb={'20px'} mt={'20px'}>
                <Skeleton
                  w={80 + Math.random() * 70}
                  h={'48px'}
                  mr={[2]}
                  borderRadius={'10px'}
                />
                <Skeleton
                  w={80 + Math.random() * 70}
                  h={'48px'}
                  mr={[2]}
                  borderRadius={'10px'}
                />
                <Skeleton
                  w={80 + Math.random() * 70}
                  h={'48px'}
                  mr={[2]}
                  borderRadius={'10px'}
                />
                <Skeleton
                  w={80 + Math.random() * 70}
                  h={'48px'}
                  borderRadius={'10px'}
                />
              </Flex>
            }
          >
            <Await resolve={categories}>
              {categories => <BlogFilters categories={categories} />}
            </Await>
          </React.Suspense>
          <SimpleGrid
            mt={['30px']}
            columns={[1, 2]}
            columnGap={[5]}
            rowGap={['30px', '20px']}
            maxW={['100%', '100%', '48em', '1100px']}
          >
            <React.Suspense
              fallback={
                <>
                  <Skeleton w={'full'} h={'380px'} borderRadius={'30px'} />
                  <Skeleton w={'full'} h={'380px'} borderRadius={'30px'} />
                </>
              }
            >
              <Await resolve={blogPosts}>
                {blogPosts => {
                  const filteredBlogs = blogPosts.filter(elem => {
                    return elem.id != featuredBlog.id;
                  });
                  return (
                    <>
                      {(filteredBlogs || []).length > 0 ? (
                        (blogPosts || [])
                          .filter(elem => {
                            return elem.id !== featuredBlog.id;
                          })
                          .map(elem => (
                            <BlogListItem key={elem?.id} item={elem} />
                          ))
                      ) : (
                        <Flex minH={['200px', '560px']}>
                          <Text as={'i'}>
                            <FormattedMessage
                              id={'blog.results.empty'}
                              description={'blog.results.empty'}
                              defaultMessage={'No Blogs Found'}
                            />
                          </Text>
                        </Flex>
                      )}
                    </>
                  );
                }}
              </Await>
            </React.Suspense>
          </SimpleGrid>
        </Flex>
      </Box>
    </Layout>
  );
};
