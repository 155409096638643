import {
  Flex,
  Text,
  AccordionItem as AccordionItemChakra,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import React from 'react';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';

export const AccordionItem = ({ children, title }) => {
  return (
    <AccordionItemChakra bg={'#F5F5F5'} borderRadius={['20px']} px={['1rem']}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            py={['1rem']}
            _hover={{
              outline: 'none',
            }}
          >
            <Flex w={['full']} justify={'space-between'} align={'center'}>
              <Text color={'#1A4B46'} fontSize={'md'} textAlign={'left'}>
                {title}
              </Text>
              {isExpanded ? <RiSubtractLine /> : <RiAddLine />}
            </Flex>
          </AccordionButton>
          <AccordionPanel>
            <Text fontSize={'sm'}>{children}</Text>
          </AccordionPanel>
        </>
      )}
    </AccordionItemChakra>
  );
};

export const CustomAccordionItem = ({ children, title }) => {
  return (
    <AccordionItemChakra bg={'#F5F5F5'} borderRadius={['20px']} px={['1rem']}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            py={['1rem']}
            _hover={{
              outline: 'none',
            }}
          >
            <Flex w={['full']} justify={'space-between'} align={'center'}>
              <Text color={'#1A4B46'} fontSize={'md'} textAlign={'left'}>
                {title}
              </Text>
              {isExpanded ? <RiSubtractLine /> : <RiAddLine />}
            </Flex>
          </AccordionButton>
          <AccordionPanel>{children}</AccordionPanel>
        </>
      )}
    </AccordionItemChakra>
  );
};
