import { Flex, Box, Heading, Image, Text } from '@chakra-ui/react';
import { React } from 'react';
export const InfoBitContainer = ({
  heading,
  description,
  height,
  width,
  src,
  alt,
  top,
  left = 0,
}) => {
  return (
    <Flex
      align={['center', 'flex-start']}
      mb={['36px', 0]}
      pr={[0]}
      maxW={['100%']}
    >
      <Box h={'100%'} minW={'55px'} position={'relative'}>
        <Box
          position={'absolute'}
          overflow={'unset !important'}
          minH={`${height}px`}
          minW={`${width}px`}
          top={`${top}px`}
          left={`${left}px`}
        >
          <Image
            loading={['lazy']}
            src={src}
            height={`${height}px`}
            width={`${width}px`}
            alt={alt}
          />
        </Box>
      </Box>
      <Box px={[2, 0]}>
        <Heading
          as={'h3'}
          size={'md'}
          mt={0}
          lineHeight={['18px', '34px']}
          textAlign={['left']}
          color={'black'}
        >
          {heading}
        </Heading>
        <Text mt={[4, 1]} textAlign={['left']} pr={[0, '35px']} fontSize={'sm'}>
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
