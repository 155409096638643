import {
  Flex,
  Box,
  Heading,
  Text,
  Image,
  AspectRatio,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const niloMain = '/images/MeetNiloNilo1.png';
const niloBaseball = '/images/MeetNiloNiloBaseball.png';
const niloHawaii = '/images/MeetNiloNiloHawaii.png';
const niloMusic = '/images/MeetNiloNiloMusic.png';
const niloHoodie = '/images/MeetNiloNiloHoodie.png';

const topBg = '/images/meetNiloTop.png';
const bottomBg = '/images/NiloBottomBgTag.png';

const bgTree = '/images/Tree.png';

const topBgHeight = '24rem';
const bottomBgHeight = '30rem';
const bottomBgMargin = '25rem';

const topBgHeightMobile = '10rem';
const bottomBgHeightMobile = '6rem';
const bottomBgMarginMobile = '6rem';

const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

let scaleDown = 1;
const NILO_COMPANIONS = [
  {
    logo: niloMusic,
    width: 241 * scaleDown,
    height: 219 * scaleDown,
    altName: 'nilo music',
    initView: { scale: 0.9 },
    whileInView: { scale: 1, transition: { duration: 0.3 } },
  },
  {
    logo: niloBaseball,
    width: 241 * scaleDown,
    height: 223 * scaleDown,
    altName: 'nilo baseball',
    initView: { scale: 0.9 },
    whileInView: { scale: 1, transition: { duration: 0.5, delay: 0.3 } },
  },
  {
    logo: niloMain,
    width: 336 * scaleDown,
    height: 306 * scaleDown,
    altName: 'nilo main',
    initView: { scale: 0.9 },
    whileInView: { scale: 1, transition: { duration: 0.8, delay: 0.6 } },
  },
  {
    logo: niloHawaii,
    width: 241 * scaleDown,
    height: 217 * scaleDown,
    altName: 'nilo hawaii',
    initView: { scale: 0.9 },
    whileInView: { scale: 1, transition: { duration: 0.5, delay: 0.3 } },
  },
  {
    logo: niloHoodie,
    width: 241 * scaleDown,
    height: 233 * scaleDown,
    altName: 'nilo hoodie',
    initView: { scale: 0.9 },
    whileInView: { scale: 1, transition: { duration: 0.3 } },
  },
];

export const MeetNiloSection = () => {
  return (
    <Flex
      id={'nilo'}
      maxW={['100%']}
      minH={['unset', '70rem']}
      w={'100%'}
      mx={'auto'}
      px={['0', '0', '2rem', '2rem']}
      justify={['center', 'space-between']}
      align={['center']}
      position={['relative']}
      bg={`linear-gradient(185.63deg, #F6E0BC 12.93%, rgba(41, 181, 189, 0.458333) 162.37%, #00ACA6 271.73%)`}
      bgSize={'cover'}
      bgRepeat={'no-repeat'}
      direction={'column'}
    >
      <Box
        position={'absolute'}
        top={'-1px'}
        height={[topBgHeightMobile, topBgHeight]}
        w={'full'}
        bg={`url('${topBg}') no-repeat`}
        bgPosition={'left top'}
        bgSize={['contain']}
      />
      <Box
        position={'absolute'}
        top={0}
        left={[0]}
        maxH={['421px']}
        maxW={['314px']}
        h={['35%']}
        w={['36%']}
        bg={`url('${bgTree}')`}
        bgRepeat={'no-repeat'}
        bgPosition={'left top'}
        bgSize={['contain']}
      />
      <Box
        position={'absolute'}
        bottom={'-1px'}
        w={'full'}
        height={[bottomBgHeightMobile, bottomBgHeight]}
        bg={`url('${bottomBg}') no-repeat`}
        bgPosition={'right bottom'}
        bgSize={['contain']}
      />
      <Flex
        mb={[bottomBgMarginMobile, bottomBgMargin]}
        mt={[topBgHeightMobile, topBgHeight]}
        minW={['100%']}
        position={'relative'}
        direction={['column']}
        justify={['center']}
      >
        <Flex
          width={['100%']}
          align={'center'}
          justify={['center']}
          px={['2rem', 0]}
        >
          <Flex
            direction={'column'}
            maxW={['100%', '850px']}
            px={['0', '0']}
            py={['50px', '0']}
            justify={['center']}
          >
            <Heading
              as={'h2'}
              size={'3xl'}
              color={'#1F4F4A'}
              pr={[0]}
              textAlign={['center']}
            >
              <FormattedMessage
                id={'nilocompanions.heading'}
                description={'nilo companions heading'}
                defaultMessage={'Meet Nilo, your NFT Companion'}
              />
            </Heading>
            <Text mt={['20px']} align={['center']} fontSize={'md'}>
              <FormattedMessage
                id={'nilocompanions.description'}
                description={'nilo companions description'}
                defaultMessage={
                  'Nilo Avatars are NFT collectibles that come with the purchase of a Smart Toothbrush. Once equipped, your brushing sessions will be able to start earning Brushing Experience Points. '
                }
              />
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={['100%']}
          mt={['20px', '100px']}
          overflow={['hidden']}
          px={['0']}
          justify={['center', 'center']}
          align={['center']}
        >
          {NILO_COMPANIONS.map(
            (
              { logo, altName, width, height, initView, whileInView },
              index
            ) => {
              if (index === 2) {
                return (
                  <MotionBox
                    minW={['40%', '20%']}
                    key={logo}
                    initial={initView}
                    whileInView={whileInView}
                  >
                    <AspectRatio maxW={width} ratio={width / height}>
                      <Image
                        loading={['lazy']}
                        src={logo}
                        alt={altName}
                        width={width}
                        height={height}
                      />
                    </AspectRatio>
                  </MotionBox>
                );
              } else {
                return (
                  <MotionBox
                    minW={['30%', '18%']}
                    key={logo}
                    initial={initView}
                    whileInView={whileInView}
                  >
                    <AspectRatio maxW={width} ratio={width / height}>
                      <Image
                        loading={['lazy']}
                        src={logo}
                        alt={altName}
                        width={width}
                        height={height}
                      />
                    </AspectRatio>
                  </MotionBox>
                );
              }
            }
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
