import { InfoBitContainer } from './InfoBitContainer';

import {
  Flex,
  Heading,
  Text,
  Image,
  SimpleGrid,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const brushAppScreen = '/images/BrushAppNiloScreens.png';
const brushNiloBrushing = '/images/Brush_nilobrushing.png';
const brushSpray = '/images/Brush_spray.png';
const brushDrip1 = '/images/BrushDrip1.png';
const brushDrip2 = '/images/BrushDrip2.png';
const brushDrip3 = '/images/BrushDrip3.png';
const brushDrip4 = '/images/BrushDrip4.png';

const brushIcon = '/images/IconBrush.png';
const historyIcon = '/images/IconHistory.png';
const bxpIcon = '/images/IconBXP.png';
const bluetoothIcon = '/images/IconBluetooth.png';

const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const initView = {
  brushDrip1: {
    scaleY: 0.2,
  },
  brushDrip2: {
    scaleY: 0.4,
  },
  brushDrip3: {
    scaleY: 0.2,
  },
  brushDrip4: {
    scaleY: 0.4,
  },
};

const durationMin = 0.4;
const durationCoeff = 0.5;
const delayCoeff = 0.0;

const whileInView = {
  brushDrip1: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
  brushDrip2: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
  brushDrip3: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
  brushDrip4: {
    scaleY: 1.0,
    transition: {
      delay: delayCoeff,
      duration: durationMin + Math.random() * durationCoeff,
    },
  },
};

export const BrushSection = () => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      id={'theapp'}
      maxW={['100%', '100%', '48em', '950px']}
      w={'100%'}
      mx={'auto'}
      pb={['0']}
      pt={['80px']}
      px={['2rem', '2rem', '1rem', '0']}
      justify={['center', 'space-between', 'space-between']}
      align={['center']}
      position={['relative']}
      bg={'#F5F5F5'}
      direction={'column'}
    >
      <Flex
        position={'relative'}
        direction={['column-reverse', 'column-reverse', 'row']}
        justify={['center', 'center', 'space-between']}
      >
        <Flex
          width={['100%', '100%', '65%', '65%']}
          maxW={['unset', 'unset', '60%', '60%']}
          align={'center'}
        >
          <Flex
            direction={'column'}
            maxW={['100%', '100%', '48em', '850px']}
            px={['0']}
            pr={['0', '0', '5rem']}
            py={['50px', '50px', '0']}
          >
            <Heading
              as={'h2'}
              size={'3xl'}
              color={'black'}
              pr={[0, 0, '30px', '30px']}
              textAlign={['center', 'center', 'left', 'left']}
            >
              <FormattedMessage
                id={'brushsection.heading'}
                description={'brush section heading'}
                defaultMessage={'Build good habits with the Nilo Toothbrush'}
              />
            </Heading>
            <Text
              mt={['20px']}
              align={['center', 'center', 'left', 'left']}
              fontSize={'md'}
            >
              <FormattedMessage
                id={'brushsection.description'}
                description={'brush section description'}
                defaultMessage={
                  'The Nilo Smart Toothbrush uses bluetooth to seamlessly connect with our mobile application. Start brushing with our application to help you track your brushing and build better oral care habits.'
                }
              />
            </Text>
          </Flex>
        </Flex>
        <Flex
          width={['100%', '100%', '35%', '35%']}
          justify={['center', 'center', 'flex-start', 'flex-start']}
        >
          <MotionBox
            w={290}
            h={290}
            display={'flex'}
            position={'relative'}
            alignItems={'center'}
            justifyContent={['center']}
          >
            <Flex
              position={'relative'}
              w={`${290}px`}
              h={`${290}px`}
              justify={['center', 'flex-start']}
            >
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.brushDrip1}
                whileInView={whileInView.brushDrip1}
                position={'absolute'}
                top={`${195}px`}
                left={`${112}px`}
              >
                <Image
                  loading={['lazy']}
                  w={`${9.5}px`}
                  h={`${87}px`}
                  src={brushDrip1}
                />
              </Flex>
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.brushDrip2}
                whileInView={whileInView.brushDrip2}
                position={'absolute'}
                top={`${204}px`}
                left={`${248}px`}
              >
                <Image
                  loading={['lazy']}
                  src={brushDrip2}
                  w={`${9.5}px`}
                  h={`${87}px`}
                />
              </Flex>
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.brushDrip3}
                whileInView={whileInView.brushDrip3}
                position={'absolute'}
                top={`${152}px`}
                left={`${262}px`}
              >
                <Image
                  loading={['lazy']}
                  src={brushDrip3}
                  w={`${9.5}px`}
                  h={`${85}px`}
                />
              </Flex>
              <Flex
                as={motion.div}
                transformOrigin={'top'}
                initial={initView.brushDrip4}
                whileInView={whileInView.brushDrip4}
                position={'absolute'}
                top={`${123}px`}
                left={`${279}px`}
              >
                <Image
                  loading={['lazy']}
                  src={brushDrip4}
                  w={`${9.5}px`}
                  h={`${143}px`}
                />
              </Flex>
              <Flex position={'relative'} w={'100%'}>
                <Image
                  loading={['lazy']}
                  position={'absolute'}
                  top={0}
                  left={0}
                  src={brushAppScreen}
                />
              </Flex>
              <Flex position={'absolute'} top={`${40}px`} left={`${0}px`}>
                <Image
                  loading={['lazy']}
                  w={`${117}px`}
                  h={`${154}px`}
                  src={brushSpray}
                />
              </Flex>

              <Flex position={'absolute'} top={`${132}px`} left={`${44}px`}>
                <Image
                  loading={['lazy']}
                  w={`${98}px`}
                  h={`${129}px`}
                  src={brushNiloBrushing}
                />
              </Flex>
            </Flex>
          </MotionBox>
        </Flex>
      </Flex>
      <SimpleGrid
        columns={[1, 1, 2, 2]}
        mt={[2, 2, 14, 14]}
        columnGap={[0, 0, 4, 4]}
        rowGap={[2, 8, 14, 14]}
      >
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.guidedbrushing.title',
            description: 'small bits guidedbrushing title',
            defaultMessage: 'Guided Brushing',
          })}
          description={formatMessage({
            id: 'smallbits.guidedbrushing.description',
            description: 'small bits guidedbrushing description',
            defaultMessage:
              'Designed to coach you on scoring the perfect brush.',
          })}
          height={48}
          width={37}
          top={-5}
          src={brushIcon}
          alt={'brush icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.brushinghistory.title',
            description: 'small bits brushinghistory title',
            defaultMessage: 'Brushing History',
          })}
          description={formatMessage({
            id: 'smallbits.brushinghistory.description',
            description: 'small bits brushinghistory description',
            defaultMessage:
              'Analyze your history to better understand your brushing habits.',
          })}
          height={45}
          width={40}
          top={-6}
          left={-1}
          src={historyIcon}
          alt={'history icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.bluetooth.title',
            description: 'small bits bluetooth title',
            defaultMessage: 'Wireless Connection',
          })}
          description={formatMessage({
            id: 'smallbits.bluetooth.description',
            description: 'small bits bluetooth description',
            defaultMessage:
              'Wirelessly connect the toothbrush with our app using Bluetooth.',
          })}
          height={41}
          width={37}
          top={-3.5}
          src={bluetoothIcon}
          alt={'bluetooth icon'}
        />
        <InfoBitContainer
          heading={formatMessage({
            id: 'smallbits.earnbxp.title',
            description: 'small bits earnbxp title',
            defaultMessage: 'Earn BXP',
          })}
          description={formatMessage({
            id: 'smallbits.earnbxp.description',
            description: 'small bits earnbxp description',
            defaultMessage:
              'Earn Brushing Experience Points from every qualified brush to level up.',
          })}
          height={42}
          width={37}
          top={-5}
          src={bxpIcon}
          alt={'bxp icon'}
        />
      </SimpleGrid>
    </Flex>
  );
};
