import {
  Box,
  Heading,
  Text,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import React from 'react';
const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const BigInfoBitContainer = ({
  heading,
  description,
  height,
  width,
  src,
  top,
  bg = '#ffffff',
  right = 0,
  bg2 = null,
  transitionOptions = {},
}) => {
  const infoBitMotion = {
    hidden: { opacity: 0 },
    whileInView: { opacity: 1, translateY: -2, transition: transitionOptions },
  };
  return (
    <MotionBox
      bg={[bg2 || bg, bg]}
      initial={infoBitMotion.hidden}
      whileInView={{ ...infoBitMotion.whileInView }}
      mb={['15px']}
      p={['1.8rem']}
      pr={['1.8rem', '1.2rem']}
      py={['2.5rem', '1.8rem']}
      position={'relative'}
      mr={[0, '15px']}
      width={['100%']}
      borderRadius={'30px'}
    >
      <Box
        position={'absolute'}
        height={height}
        width={width}
        top={`${top}px`}
        right={`${right}px`}
        bg={`url(${src})`}
        bgRepeat={'no-repeat'}
        bgSize={'contain'}
      />
      <Heading
        as={'h3'}
        size={'md'}
        mt={[0]}
        lineHeight={['25px']}
        textAlign={['left']}
        color={'black'}
        pr={['60px']}
        mb={['0']}
      >
        {heading}
      </Heading>
      <Text
        mt={[4, 4]}
        color={'#666666'}
        textAlign={['left']}
        pr={[0, '1rem']}
        fontSize={'sm'}
      >
        {description}
      </Text>
    </MotionBox>
  );
};
