import { configureAuth } from 'react-query-auth';
import {
  getUserProfile,
  registerWithEmailAndPassword,
  loginWithEmailAndPassword,
  logout,
} from 'src/features/auth';
import storage from 'src/utils/storage';

//async function handleUserResponse(data) {
//  const { jwt, user } = data;
//  storage.setToken(jwt);
//  return user;
//}

async function userFn() {
  try {
    const { user } = await getUserProfile();
    return user ?? null;
  } catch (error) {
    return null;
  }
}

async function loginFn(data) {
  const response = await loginWithEmailAndPassword(data);
  return response;
  /*await handleUserResponse(response);
  try {
    const user = await userFn();
    return user;
  } catch (error) {
    return null;
  }*/
}

async function registerFn(data) {
  const response = await registerWithEmailAndPassword(data);
  //const user = await handleUserResponse(response);
  return response;
}

async function logoutFn() {
  await logout();
  storage.clearToken();
  window.location.assign(window.location.origin);
}

export const { useUser, useLogin, useRegister, useLogout, AuthLoader } =
  configureAuth({
    userFn,
    loginFn,
    registerFn,
    logoutFn,
  });
