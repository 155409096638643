import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Head = ({
  title = 'Nilo',
  subtitle = '',
  description,
  imageUrl,
  canonicalUrl,
  extras = null,
}) => {
  description = description
    ? description
    : 'Meet Nilo - an innovative oral care brand fusing smart toothbrush tech & Web3 rewards for an interactive brushing experience.';
  imageUrl = imageUrl ? imageUrl : '/images/NiloLogo.png';
  canonicalUrl = canonicalUrl ? canonicalUrl : 'https://nilo.gg';

  return (
    <Helmet
      title={title ? `${title} ${subtitle ? `- ${subtitle}` : ''}` : 'Nilo'}
      defaultTitle="Nilo"
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={canonicalUrl} />
      <link rel="canonical" href="https://nilo.gg" />
      {extras}
    </Helmet>
  );
};
