import { Footer } from './Footer';
import { Navbar } from './Navbar';

import { Spinner } from '../Elements/Spinner/Spinner';

import { Box, Flex, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import React from 'react';
import { useNavigation } from 'react-router-dom';
import { Head } from 'src/components/Head/Head';

export const Layout = ({
  children,
  title,
  subtitle,
  headTitle,
  description = null,
  imageUrl = null,
  extras = null,
}) => {
  const navigation = useNavigation();
  return (
    <Box h="full" w="full" bg={'#f5f5f5'}>
      <Head
        title={headTitle || title}
        subtitle={subtitle}
        description={description}
        imageUrl={imageUrl}
        extras={extras}
      />
      <Navbar />
      <Box bg={'#f5f5f5'}>
        <Modal
          isCentered
          closeOnOverlayClick={false}
          isOpen={navigation.state === 'loading'}
        >
          <ModalOverlay bg="blackAlpha.100" />
          <ModalContent bg={'transparent'} boxShadow={'none'}>
            <Flex
              bg={'transparent'}
              alignContent={'center'}
              justifyContent={'center'}
              w={'100%'}
            >
              <Spinner />
            </Flex>
          </ModalContent>
        </Modal>
        <Box>{children}</Box>
      </Box>
      <Footer />
    </Box>
  );
};
