// 1. Import `extendTheme`
import { inputAnatomy } from '@chakra-ui/anatomy';
import { extendTheme, createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseInputStyle = definePartsStyle({
  // define the part you're going to style
  baseStyle: {
    bgColor: '#FAFAFD',
  },
  field: {
    borderRadius: '10px',
    border: '1px solid #9b9b9b',
    borderColor: '#9b9b9b',
    _placeholder: {
      color: '#666666',
    },
  },
});

const inputTheme = defineMultiStyleConfig({ baseInputStyle });

export const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors: {
    primary: {
      50: '#FFFFFF',
      100: '#F4F8FB',
      200: '#CDEAEB',
      300: '#88E3DC',
      400: '#009482',
      500: '#1A4B46',
      600: '#1A4B46',
      700: '#1A4B46',
      800: '#0C3430',
      900: '#072220',
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: '#1A4B46',
      },
      sizes: {
        '4xl': {
          fontSize: '2.5rem',
          lineHeight: 'short',
          letterSpacing: ' -0.011em',
        },
        '3xl': {
          fontSize: '2rem',
          lineHeight: 'base',
        },
        '2xl': {
          fontSize: '1.5rem',
          lineHeight: 'base',
        },
        xl: {
          fontSize: 'xl',
        },
        lg: {
          fontSize: 'lg',
        },
      },
      variants: {
        gridTitle: {
          fontSize: 'lg',
          fontWeight: 500,
          fontFamily: `'Inter', sans-serif`,
        },
      },
    },
    Text: {
      baseStyle: {
        color: '#666666',
        lineHeight: 'taller',
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 10,
      },
    },
    Input: inputTheme,
  },
});
