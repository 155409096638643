import { Spinner as ChakraSpinner } from '@chakra-ui/react';
import React from 'react';

export const Spinner = ({ ...props }) => {
  return (
    <ChakraSpinner
      thickness={'10px'}
      speed={'0.65s'}
      emptyColor={'gray.200'}
      color={'primary'}
      w={['100px']}
      h={['100px']}
      size={'xl'}
      {...props}
    />
  );
};
