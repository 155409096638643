import { axios } from 'src/lib/axios';

//export type LoginCredentialsDTO = {
//  email: string;
//  password: string;
//};

export const loginWithEmailAndPassword = data => {
  return axios.post('/auth/login', data);
};
