import React from 'react';
export default function ShoppingBagIcon() {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" color="#ffffff">
      <path
        d="M23.8645 0.325195C24.6232 0.325257 25.3564 0.599159 25.9293 1.09656C26.5022 1.59396 26.8764 2.28143 26.983 3.03262L27.0097 3.27832L28.3878 25.3283C28.4399 26.1557 28.1637 26.9702 27.6191 27.5953C27.0745 28.2203 26.3055 28.6055 25.4788 28.6673L25.2426 28.6752H3.3989C2.56998 28.6753 1.77444 28.3486 1.18477 27.766C0.595109 27.1834 0.258848 26.3919 0.248901 25.563L0.253626 25.3283L1.63175 3.27832C1.67915 2.52086 1.99843 1.80597 2.53087 1.26513C3.06331 0.724298 3.77312 0.39388 4.52975 0.334645L4.77545 0.325195H23.8645ZM23.8645 3.4752H4.77545L3.39733 25.5252H25.2426L23.866 3.4752H23.8645ZM19.045 6.6252C19.4627 6.6252 19.8633 6.79113 20.1586 7.0865C20.454 7.38187 20.62 7.78248 20.62 8.2002C20.62 9.87106 19.9562 11.4735 18.7747 12.655C17.5932 13.8364 15.9908 14.5002 14.32 14.5002C12.6491 14.5002 11.0467 13.8364 9.86518 12.655C8.6837 11.4735 8.01995 9.87106 8.01995 8.2002C8.01995 7.78248 8.18589 7.38187 8.48126 7.0865C8.77663 6.79113 9.17724 6.6252 9.59495 6.6252C10.0127 6.6252 10.4133 6.79113 10.7086 7.0865C11.004 7.38187 11.17 7.78248 11.17 8.2002C11.1663 9.01759 11.4805 9.80441 12.0462 10.3944C12.612 10.9844 13.3849 11.3314 14.2017 11.3621C15.0186 11.3927 15.8153 11.1047 16.4237 10.5588C17.0321 10.0129 17.4044 9.25182 17.4621 8.43644L17.4699 8.2002C17.4699 7.78248 17.6359 7.38187 17.9313 7.0865C18.2266 6.79113 18.6272 6.6252 19.045 6.6252Z"
        stroke="transparent"
        fill="#fff"
      />
    </svg>
  );
}
