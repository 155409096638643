import { BigInfoBitContainer } from './BigInfoBit';

import {
  Flex,
  Heading,
  Text,
  Image,
  SimpleGrid,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const brushAvatar1 = '/images/BrushEvolveAvatarHawaii.png';
const brushAvatar2 = '/images/BrushEvolveAvatarNormal.png';
const brushAvatar3 = '/images/BrushEvolveAvatarTeen.png';
const brushAvatar4 = '/images/BrushEvolveAvatarHatchling.png';
const brushAvatar5 = '/images/BrushEvolveAvatarEgg.png';

const attributesIcon = '/images/IconAttributes.png';
const collectibleIcon = '/images/IconNftCollectible.png';
const equipGearIcon = '/images/IconEquipGear.png';
const marketplaceIcon = '/images/IconMarketplace.png';

const initView = {
  brushAvatar1: { scale: 0.9 },
  brushAvatar2: { scale: 0.9 },
  brushAvatar3: { scale: 0.9 },
  brushAvatar4: { scale: 0.9 },
  brushAvatar5: { scale: 0.9 },
};

const whileInView = {
  brushAvatar1: { scale: 1, transition: { duration: 0.6, delay: 0.6 } },
  brushAvatar2: { scale: 1, transition: { duration: 0.4, delay: 0.4 } },
  brushAvatar3: { scale: 1, transition: { duration: 0.3, delay: 0.3 } },
  brushAvatar4: { scale: 1, transition: { duration: 0.2, delay: 0.2 } },
  brushAvatar5: { scale: 1, transition: { duration: 0.2, delay: 0.1 } },
};

const transitionOptions = {
  icon1: { delay: 0.1 },
  icon2: { delay: 0.3 },
  icon3: { delay: 0.4 },
  icon4: { delay: 0.5 },
};

const MotionBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const BrushEvolveSection = () => {
  const { formatMessage } = useIntl();
  return (
    <Flex
      maxW={['100%', '100%', '48em', '950px']}
      w={'100%'}
      mx={'auto'}
      pb={['0']}
      pt={['80px']}
      px={['2rem', '2rem', '0']}
      justify={['center', 'center', 'space-between']}
      align={['center']}
      position={['relative']}
      bg={'#F5F5F5'}
      direction={'column'}
    >
      <Flex
        direction={'column'}
        maxW={['100%', '100%', '48em', '850px']}
        px={['0', '0']}
        py={['50px', '0']}
        justify={['center']}
      >
        <Heading as={'h2'} size={'3xl'} pr={[0]} textAlign={['center']}>
          <FormattedMessage
            id={'brushevolve.heading'}
            description={'brushevolve brush evolve heading'}
            defaultMessage={'Brush & Evolve'}
          />
        </Heading>
        <Text mt={['20px']} align={['center']} fontSize={'md'}>
          <FormattedMessage
            id={'brushevolve.description'}
            description={'brushevolve brush evolve description'}
            defaultMessage={
              'Nilos are first hatched without experience and its your mission to follow a healthy brushing habit to earn the most rewards and experience points. As you continue to brush with your Nilo, they will earn BXP, level up, and evolve into an adult.'
            }
          />
        </Text>
      </Flex>

      <Flex
        position={'relative'}
        direction={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row-reverse',
        ]}
        justify={['center', 'center', 'space-between']}
        mt={['50px']}
      >
        <Flex
          width={['100%', '100%', '100%', '55%']}
          mt={['40px']}
          align={'center'}
        >
          <SimpleGrid columns={[1, 2]} columnGap={[4, 4]}>
            <BigInfoBitContainer
              heading={formatMessage({
                id: 'infobit.attributes.title',
                description: 'infobit attributes title',
                defaultMessage: 'Dynamic Attributes',
              })}
              description={formatMessage({
                id: 'infobit.attributes.description',
                description: 'infobit attributes description',
                defaultMessage:
                  'All Nilo’s grow according to the player’s brushing habits and their custom level-up strategy.',
              })}
              height={111}
              width={113}
              top={-24}
              right={-10}
              src={attributesIcon}
              alt={'attributes icon'}
              bg={'#F6E0BC'}
              transitionOptions={transitionOptions.icon1}
            />
            <BigInfoBitContainer
              heading={formatMessage({
                id: 'infobit.nftcollectible.title',
                description: 'infobit nftcollectible title',
                defaultMessage: 'Binding NFT Collectible',
              })}
              description={formatMessage({
                id: 'infobit.nftcollectible.description',
                description: 'infobit nftcollectible description',
                defaultMessage:
                  'Nilos are mandatory to earn rewards in the Nilo app from qualified brushing sessions.',
              })}
              height={91}
              width={85}
              top={-7}
              right={8}
              src={collectibleIcon}
              alt={'collectible icon'}
              bg={'#ADD7CD'}
              transitionOptions={transitionOptions.icon2}
            />
            <BigInfoBitContainer
              heading={formatMessage({
                id: 'infobit.equipgear.title',
                description: 'infobit equipgear title',
                defaultMessage: 'Equip Gear',
              })}
              description={formatMessage({
                id: 'infobit.equipgear.description',
                description: 'infobit equipgear description',
                defaultMessage:
                  'Enable equipment slots by evolving your Nilo into an adult then equip unique Gears to boost your BXP earnings.',
              })}
              height={105}
              width={105}
              top={-23}
              right={-10}
              src={equipGearIcon}
              alt={'equipgear icon'}
              bg={'#ADD7CD'}
              bg2={'#F6E0BC'}
              transitionOptions={transitionOptions.icon3}
            />
            <BigInfoBitContainer
              heading={formatMessage({
                id: 'infobit.marketplace.title',
                description: 'infobit marketplace title',
                defaultMessage: 'Marketplace',
              })}
              description={formatMessage({
                id: 'infobit.marketplace.description',
                description: 'infobit marketplace description',
                defaultMessage:
                  'Nilos (and Gear) and can be bought and solid in our marketplace, along with other NFT marketplaces.',
              })}
              height={86}
              width={90}
              top={-13}
              right={-8}
              src={marketplaceIcon}
              alt={'marketplace icon'}
              bg={'#F6E0BC'}
              bg2={'#ADD7CD'}
              index={4}
              transitionOptions={transitionOptions.icon4}
            />
          </SimpleGrid>
        </Flex>
        <Flex
          width={['100%', '100%', '100%', '45%']}
          justify={['center', 'center', 'center', 'flex-start']}
          align={['center']}
        >
          <MotionBox
            w={336}
            h={352}
            display={'flex'}
            position={'relative'}
            alignItems={'center'}
            justifyContent={['center']}
          >
            <Flex
              position={'relative'}
              w={`${336}px`}
              h={`${352}px`}
              justify={['center', 'flex-start']}
            >
              <Flex
                as={motion.div}
                position={'absolute'}
                top={`${20}px`}
                left={`${0}px`}
                initial={initView.brushAvatar1}
                whileInView={whileInView.brushAvatar1}
              >
                <Image
                  loading={['lazy']}
                  w={`${227}px`}
                  h={`${240}px`}
                  src={brushAvatar1}
                />
              </Flex>
              <Flex
                as={motion.div}
                position={'absolute'}
                top={`${230}px`}
                left={`${126}px`}
                initial={initView.brushAvatar2}
                whileInView={whileInView.brushAvatar2}
              >
                <Image
                  loading={['lazy']}
                  w={`${144}px`}
                  h={`${122}px`}
                  src={brushAvatar2}
                />
              </Flex>
              <Flex
                as={motion.div}
                position={'absolute'}
                top={`${155}px`}
                left={`${220}px`}
                initial={initView.brushAvatar3}
                whileInView={whileInView.brushAvatar3}
              >
                <Image
                  loading={['lazy']}
                  w={`${140}px`}
                  h={`${106}px`}
                  src={brushAvatar3}
                />
              </Flex>
              <Flex
                as={motion.div}
                position={'absolute'}
                top={`${64.5}px`}
                left={`${248}px`}
                initial={initView.brushAvatar4}
                whileInView={whileInView.brushAvatar4}
              >
                <Image
                  loading={['lazy']}
                  w={`${82}px`}
                  h={`${82}px`}
                  src={brushAvatar4}
                />
              </Flex>
              <Flex
                as={motion.div}
                position={'absolute'}
                top={`${1}px`}
                left={`${207}px`}
                initial={initView.brushAvatar5}
                whileInView={whileInView.brushAvatar5}
              >
                <Image
                  loading={['lazy']}
                  w={`${58}px`}
                  h={`${63.5}px`}
                  src={brushAvatar5}
                />
              </Flex>
            </Flex>
          </MotionBox>
        </Flex>
      </Flex>
    </Flex>
  );
};
